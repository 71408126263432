import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextError from './TextError'

function Checkbox(props) {
    const {label, name, option, ...rest} = props
    return (
        <div className='form-control form-check'>
            {label && <label>{label}</label>}
            <Field name={name} type='checkbox'/>
            <ErrorMessage component={TextError} name={name}/>
        </div>
    )
}

export default Checkbox
