import moment from 'moment';
import {useLayoutEffect, useState} from "react";
import {authStore} from "../mobxStores/auth.store";

export const parsePhoneNumber = (number) => {
    return number.replace(/-|\s/g, '');
};

export function useMediaQuery() {
    const [screenSize, setScreenSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

/**
 * @func to trim all form fields values from whitespaces
 * */
export const trimAll = (obj) => {
    return Object.keys(obj).map(
        (k) => (obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k])
    );
};

/**
 * @func to display the first two roles and the count of the rest
 * */
export const rolesCount = (arr) => {
    return arr.reduce((accumulator, currentValue, currentIndex, array) => {
        if (currentIndex < 2) accumulator.push(currentValue);
        if (currentIndex === 2)
            accumulator.push({nameEn: `+${array.length - 2}`});
        return accumulator;
    }, []);
};

export const toAWSDate = (date) => {
    try {
        return moment(date).format('YYYY-MM-DD');
    } catch (e) {
        throw e;
    }
};

/**
 * For convert time 12 to 24 hours format
 * @param {*} time12h
 */
export const convertTime12to24 = (time12h) => {
    try {
        return moment(time12h, ['hh:mm a']).format('HH:mm');
    } catch (e) {
        throw e;
    }
};

export const checkConvertToCelsius = (temp) => {
    if (authStore.userUnit === 'f') {
        return convertFahrenheitToCelsius(temp)
    }
    return temp
}
export const checkConvertToFahrenheit = (temp) => {
    if (authStore.userUnit === 'f') {
        return temp
    }
    return convertCelsiusToFahrenheit(temp)
}
export const convertCelsiusToFahrenheit = (temp) => {
    if (temp) {
        return Math.floor((temp * 9 / 5) + 32);
    }
}
export const convertFahrenheitToCelsius = (temp) => {
    if (temp) {
        return Math.round((temp - 32) * 5 / 9);
    }
}
export const convertKmToMiles = (speed) => {
    if (speed === 0) return 0;
    if (speed) {
        return Math.floor(speed / 1.609);
    }
}

/**
 * For convert time 24 to 12 hours format
 * @param {*} time24h
 */
export const convertTime24to12 = (time24h) => {
    try {
        return moment(time24h, ['HH:mm']).format('hh:mm a');
    } catch (e) {
        throw e;
    }
};

/**
 * Set localstorage data
 * @param {*} key
 * @param {*} value
 */
export const setLocalStorageData = (key, value) => {
    const enc = JSON.stringify(value);
    sessionStorage.setItem(key, window.btoa(unescape(encodeURIComponent(enc))));
};

/**
 * Get the localstorage data
 * @param {*} key
 */
export const getLocalStorageData = (key) => {
    if (sessionStorage.getItem(key)) {
        const dec = decodeURIComponent(
            escape(window.atob(sessionStorage.getItem(key)))
        );
        return JSON.parse(dec);
    }
    return null;
};

/**
 * Remove the localstorage data
 * @param {*} key
 */
export const removeLocalStorageData = (key) => {
    sessionStorage.removeItem(key);
};

/**
 *
 * Creates a localized list out of a lookup.
 *
 */

export const createLocalizedList = (
    list = [],
    locale = 'en',
    valueKey = 'id'
) => {
    return list.map((lookupItem) => ({
        label: locale === 'en' ? lookupItem.nameEn : lookupItem.nameFr,
        value: lookupItem[valueKey],
    }));
};

export const isFalsyForm = (form) => {
    return Object.keys(form.errors).length > 0;
};

export const selectPlatform = () => {
    let platform = 'blower';
    if (localStorage.getItem('platform')) {
        if (localStorage.getItem('platform') === 'blowers')
            platform = 'blower';
        else platform = 'switch'
    }

    return platform;
}
