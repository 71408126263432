import React, {useEffect} from 'react';
import {
    Switch,
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';
import NonAuthLayout from './components/NonAuthLayout';
import {observer} from 'mobx-react';
// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';
import NonAuthmiddleware from './routes/middleware/NonAuthmiddleware';
import Dashboard from './pages/Dashboard';
import {authRoutes, homeRoutes} from './routes/allRoutes';
import useTheme from './hooks/useTheme';
import Loader from "./components/Loader";
import {useStore} from "./mobxStores/stores";
import {disconnectSocket, initiateSocket} from "./context/socket";

const App = () => {
    const {authStore: {dark, getUserData, loading, userData}} = useStore();

    // Enable themes
    useTheme(dark);
    // get the user data first
    useEffect(() => {
        initiateSocket();
        return () => {
            disconnectSocket();
        }
    }, []);

    useEffect(async () => {
        await getUserData()
    }, [])


    if (loading) return <></>;
    console.log('authStore.loading', loading);

    const _renderRoutes = () => {
        if (loading) return <><Loader/></>;
        if (userData) {
            return homeRoutes.map((route, idx) => (
                <Authmiddleware
                    path={route.path}
                    layout={Dashboard}
                    component={route.component}
                    key={idx}
                />
            ))
        }
        return <Redirect to={"/login"}/>
    };
    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <NonAuthmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                        />
                    ))}
                    {_renderRoutes()}
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default observer(App);
