import React from "react";
import {Col, Row, Typography} from "antd";

import PersonalInfo from "./PersonalInfo";
import Units from "./Units";
import ChangePassword from "./ChangePassword";
import OtherSettings from "./OtherSettings";

import './styles.scss';

const Settings = () => {
    return (
        <div className="Settings">

            <Typography.Title className="title">Settings</Typography.Title>

            <PersonalInfo/>

            <Row gutter={19}>

                <Col className="Units">

                    <Units/>

                </Col>

                <Col className="ChangePassword" style={{width: 'calc(100% - 294px)'}}>

                    <ChangePassword/>

                </Col>

            </Row>

            <div className="other">

                <Row>

                    <Col className="OtherSettings">

                        <OtherSettings/>

                    </Col>

                </Row>

            </div>

        </div>
    );
}

export default Settings;
