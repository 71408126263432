import {useApi as RestApi} from "../utils/API";
import api from "../utils/EndPoints";

const {post, get} = RestApi();

const login = async (payload) => {
    try {
        return await post(api.login, payload);
    } catch (error) {
        throw error;
    }
};
const updateUser = async (payload) => {
    try {
        console.log(payload);
        return await post(api.updateUser, payload);
    } catch (error) {
        throw error;
    }
}
const getUserData = async () => {
    try {
        return await get(api.getUserData);
    } catch (error) {
        throw error;
    }
}
export {login, getUserData, updateUser};
