import React from "react";
import {Button, Card, Form, Input, message, Typography} from "antd";
import {ReactComponent as Eye} from "../../../assets/svg/Eye.svg";
import {ReactComponent as EyeClosed} from "../../../assets/svg/EyeClosed.svg";
import {authStore} from "../../../mobxStores/auth.store";
import './styles.scss';

const ChangePassword = () => {

    const [form] = Form.useForm();

    const cardBodyStyle = {padding: '20px 24px 25px'};

    const {updateUser} = authStore;

    const onFinish = async (values) => {
        console.log(values);
        try {

            await updateUser({
                user: {
                    "current_password": values.current_password,
                    "new_password": values.new_password
                }
            });

            form.resetFields();

            message.success('change password successfully')

        } catch (error) {
            message.error(error.response.data.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="ChangePassword">

            <Typography.Title className="text-uppercase text-3f3f3f title">Change Password</Typography.Title>

            <Card
                className="Card"
                bordered={false}
                bodyStyle={cardBodyStyle}
            >

                <div className="CardContent">

                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        requiredMark='optional'
                    >
                        <Form.Item
                            label="Current Password*"
                            name="current_password"
                            rules={[{required: true, message: 'Please input your current password!'}]}
                            className="Item text-uppercase text-979797 mb-0"
                        >

                            <Input className="Input" type="password" placeholder="********" suffix={<EyeClosed/>}/>

                        </Form.Item>

                        <div className="newPasswordSection d-flex justify-content-start align-items-center">

                            <Form.Item
                                label="new Password*"
                                name="new_password"
                                rules={[{required: true, message: 'Please input your new password!'}]}
                                className="Item text-uppercase text-979797 m-0"
                            >
                                <Input className="Input" type="password" placeholder="" suffix={<Eye/>}/>

                            </Form.Item>

                            <Form.Item
                                label="confirm Password*"
                                name="confirm_password"
                                dependencies={['password']}
                                rules={[
                                    {required: true, message: 'Please input your confirm password!'},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('new_password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                                className="Item text-uppercase text-979797 m-0"
                            >
                                <Input className="Input" type="password" placeholder="" suffix={<Eye/>}/>

                            </Form.Item>

                            <Button className="Button m-0" htmlType="submit" type="primary">Submit</Button>

                        </div>

                    </Form>

                </div>

            </Card>

        </div>
    );
}

export default ChangePassword;
