import {React, useLayoutEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {AuthLayoutContainer} from "../../../components/AuthLayout";
import {ReactComponent as LoginLogo} from '../../../assets/svg/LoginLogo.svg';
import {Button, Checkbox, Form, Input, message, Row, Typography,} from "antd";
import {Formik} from 'formik';
import {Col} from "react-bootstrap";
import {useStore} from "../../../mobxStores/stores";
import {useHistory} from "react-router-dom";
import '../index.scss';

const LoginForm = () => {
    const {authStore} = useStore();
    const history = useHistory();
    const [form] = Form.useForm();
    const [remember, setRemember] = useState(true);
    const [formLayout] = useState('vertical');
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    const tailLayout = {
        wrapperCol: {offset: 0, span: 24},
    };
    const onFinish = async (values) => {
        console.log('Success:', values);
        try {
            await authStore.loginUser(values);
            history.push('/dashboard');
        } catch (e) {
            console.log({response: e.response})
            if (e.response.status === 401) {
                message.error(e.response.data.errors.User)
            }
            if (e.response.status === 400) {
                message.error(e.response.data.message)
            }
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useLayoutEffect(() => {
        authStore.setUserMode(0);
    }, []);
    return (
        <AuthLayoutContainer>
            <div className="Login">
                <Row className="vh-100 no-gutters">
                    <Col xs={12} lg={6} className="gutter-row d-none d-lg-block">
                        <div className="left-bg"></div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="LoginContent">
                            <div className="LoginHeader">
                                <LoginLogo/>
                                <h3 className="text-3f3f3f">Umbrella Operating System</h3>
                                <p className="text-979797">Welcome Back! Please login to your account</p>
                            </div>

                            <Formik
                                initialValues={{email: '', password: '', platform: 'blowers'}}
                                validate={values => {
                                    const errors = {};
                                    if (!values.email) {
                                        errors.email = 'Required';
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = 'Invalid email address';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        alert(JSON.stringify(values, null, 2));
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      /* and other goodies */
                                  }) => (
                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                        requiredMark='optional'
                                        // name="basic"
                                        initialValues={{remember: remember}}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            className="mb-17"
                                            label={
                                                <span className="labelForm text-979797">Username</span>
                                            }
                                            name="email"
                                            rules={[{required: true, message: 'Please input your username!'}]}
                                        >
                                            <Input className="h-48px"/>
                                        </Form.Item>

                                        <Form.Item
                                            className="mb-24"
                                            label={
                                                <span className="labelForm text-979797">Password</span>
                                            }
                                            name="password"
                                            rules={[{required: true, message: 'Please input your password!'}]}
                                        >
                                            <Input.Password className="h-48px"/>
                                        </Form.Item>
                                        <Form.Item className="text-20ace8 mb-47" {...tailLayout} name="remember"
                                                   valuePropName="checked">
                                            <Checkbox checked={remember} onChange={() => {
                                                setRemember(!remember);
                                                console.log(remember)
                                            }}>Remember me</Checkbox>
                                            <Link to='/forgit'><span
                                                className="text-20ace8">Forgit Password?</span></Link>
                                        </Form.Item>

                                        <Form.Item {...tailLayout} className="m-0">
                                            <Button className="d-block h-48px w-100 bg-pri" type="primary"
                                                    htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                )}
                            </Formik>
                            <Typography.Text className="Privacy text-979797">
                                Read our <span><Link to="/" className="text-979797">T&C</Link></span> - <span><Link
                                to="/" className="text-979797">Privacy Policy</Link></span>
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
            </div>
        </AuthLayoutContainer>
    );
};


export default LoginForm;
