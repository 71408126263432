import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {useLocation} from "react-router";

const NonAuthLayout = (props) => {

    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    const useQuery = (key) => {
        return new URLSearchParams(useLocation().search).get(key);
    }

    const lang = useQuery('lang')

    const location = useLocation();

    useEffect(() => {

        let currentage = capitalizeFirstLetter(location.pathname);

        document.title =
            currentage + " | UOS";

        return () => {
        };
    });

    return (
        <>
            {props.children}
        </>
    );

}
export default withRouter(NonAuthLayout);
