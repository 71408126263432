import React from 'react';
import {Field} from 'formik';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';

function ErrorMessage({message}) {
    return <>{message}</>;
}

function DisplayError({error = ''}) {
    return <ErrorMessage message={error}/>;
}

function GInput(props) {
    const {
        className,
        required,
        style,
        label,
        name,
        multipleErrors,
        isValid,
        type,
        ...rest
    } = props;

    return (
        <FormGroup style={style}>
            {label && (
                <label className={required && 'required'} htmlFor={name}>
                    {label}
                </label>
            )}
            <Field className={'form-control'} id={name} name={name} {...rest}>
                {({field, meta}) => {
                    return (
                        <>
                            <Input
                                type={type ? type : 'text'}
                                valid={isValid ? !meta.error && meta.touched : false}
                                invalid={meta.error && meta.touched}
                                className={
                                    [className,
                                        ((meta.touched && !meta.errors && meta.value) ||
                                            meta.value) &&
                                        'valid']
                                }
                                {...field}
                                {...rest}
                            />
                        </>
                    );
                }}
            </Field>
        </FormGroup>
    );
}

export default GInput;
