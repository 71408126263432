import * as React from "react"

function TempIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={37}
            height={39}
            fill="none"
            viewBox="0 0 37 39"
            {...props}
        >
            <g filter="url(#prefix__filter0_d)">
                <circle cx={18.5} cy={18.5} r={18.5} fill="#fff"/>
                <circle cx={18.5} cy={18.5} r={18} stroke="#E0E0E0"/>
            </g>
            <path
                fill={props.color}
                d="M24.018 14.348a1.839 1.839 0 100 3.68 1.839 1.839 0 000-3.681zm0 2.628a.794.794 0 01-.728-.487.79.79 0 01.171-.859.783.783 0 01.859-.171.79.79 0 01.487.728.788.788 0 01-.789.789zm-3.416 3.368v-6.259a2.627 2.627 0 00-5.256 0v6.26a3.932 3.932 0 00-1.202 2.008 3.935 3.935 0 001.595 4.176 3.938 3.938 0 004.471 0 3.95 3.95 0 001.447-1.84 3.949 3.949 0 00-1.055-4.345zm-2.628-7.836a1.578 1.578 0 011.577 1.577v2.102h-3.153v-2.102a1.578 1.578 0 011.576-1.577z"
            />
            <defs>
                <filter
                    id="prefix__filter0_d"
                    width={37}
                    height={39}
                    x={0}
                    y={0}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy={2}/>
                    <feColorMatrix values="0 0 0 0 0.302921 0 0 0 0 0.314445 0 0 0 0 0.374942 0 0 0 0.08 0"/>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default TempIcon
