import React from "react";
import Sider from "antd/es/layout/Sider";
import classNames from "classnames";
import {Link, NavLink} from "react-router-dom";
import {useStore} from "../../mobxStores/stores";
import {Button, Dropdown, Menu, Typography} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {ReactComponent as Logo} from "../../assets/svg/Group 1683.svg";
import {ReactComponent as SquaresFour} from "../../assets/svg/SquaresFour.svg";
import {ReactComponent as GearSix} from "../../assets/svg/GearSix.svg";
import {ReactComponent as Caret} from "../../assets/svg/CaretDown_4.svg";
import './styles.scss';

const Sidebar = ({collapsed, classes}) => {

    const {authStore: {getNickName, userName, userRole, logout}} = useStore();

    const handleMenuClick = (e) => {
        logout();
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item className="d-flex justify-content-center align-items-center" key="1" icon={<LogoutOutlined/>}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Sider
            trigger={null}
            className={
                classNames(['sidebar', ...classes])
            }
            collapsible
            breakpoint={{
                xs: '480px',
                sm: '576px',
                md: '768px',
                lg: '992px',
                xl: '1200px',
                xxl: '1600px',
            }}
            collapsed={collapsed}
        >

            <div className="logo">

                <Link to="/">

                    <Logo/>

                </Link>

                <div className="logo-v">Ver <span>1.0</span></div>

            </div>

            <Menu
                theme="dark"
                className="bg-transparent"
                mode="inline"
            >

                <Menu.Item className="MenuItem" key="1">

                    <NavLink
                        activeClassName="active"
                        to='/dashboard'
                    >
                        <SquaresFour/>
                        <span>Dashboard</span>
                    </NavLink>

                </Menu.Item>

                <Menu.Item
                    className="MenuItem"
                    key="2"
                >
                    <NavLink
                        activeClassName="active"
                        to='/settings'
                    >
                        <GearSix/>
                        <span>Settings</span>
                    </NavLink>

                </Menu.Item>

            </Menu>

            <div className="footer-sidebar">

                <Dropdown overlay={menu} placement="topCenter">

                    <Button className="Button d-flex justify-content-between align-items-center">

                        <div className="nickname text-uppercase">{getNickName}</div>

                        <div className="NameWithRole">

                            <Typography.Title className="title" level={5}>{userName}</Typography.Title>

                            <Typography.Text className="role">{userRole}</Typography.Text>

                        </div>

                        <Caret fill="#208ee8"/>

                    </Button>

                </Dropdown>

            </div>

        </Sider>
    );
};

export default Sidebar;
