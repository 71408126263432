export default {
    login: `/login`,
    getUserData: '/me',
    getZones: `/get-zones/`,
    getCurrentZone: `/get-zone/`,
    updateUser: '/update-user',
    updateDeviceUrl: '/update-',
    masterSnowSensor: '/master-controls/snow-sensor/',
    setOverallTemp: '/master-controls/overall-temp',
    windTimer: '/wind-timer',
};
