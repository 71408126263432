import React from "react";
import {authStore} from "../../mobxStores/auth.store";
import Text from "antd/es/typography/Text";
import classNames from "classnames";
import Icon from "../Icon";


export const CountryWeather = ({item}) => {
    const {userUnit} = authStore;

    const classesWeather = classNames({
        'country-weather': true,
        'country-weather-active': item.isActive ? true : false
    })

    return (
        <div className={classesWeather}>
            <div className="country-weather-icon">
                <Icon
                    name={item.icon}
                    fill={item.isActive ? '#20ace8' : '#979797'}
                    stroke={item.isActive ? '#20ace8' : '#979797'}
                />
            </div>
            <Text className="country-weather-title">
                {item.temp}<sup>°</sup>{userUnit.toUpperCase()}
            </Text>
        </div>
    );
}
