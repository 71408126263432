const light = {
    '--bg-main': '#f9f9f9',
    '--bg-white': '#ffffff',
    '--bg-divider': '#dfdfdf',
    '--bg-navbar': '#0c1938',
    '--bg-btn': '#e5e5e5',
    '--bg-navbar-header': '#283553',
    '--bg-card': '#FFFFFF',
    '--text-title-color': '#3f3f3f',
    '--text-title-zone': '#3f3f3f',
    '--text-subtitle-color': '#979797',
    '--text-primary': '#20ace8',
    '--text-white': '#ffffff',
    '--text-a6a6a6': '#a6a6a6',
    '--text-color': '#979797',
    '--text-color-black': '#000000',
    '--btn-custom': '#ffffff',
    '--btn-custom-border': 'rgb(238, 238, 238)',
    '--bg-table-head': '#f6f6f6',
    '--bg-wind-start': '#f4f4f4',


};

const dark = {
    '--bg-main': '#1f1d2b',
    '--bg-white': '#2b293a',
    '--bg-divider': 'rgba(223, 223, 223, 0.17)',
    '--bg-navbar': '#1b1927',
    '--bg-btn': '#4d4960',
    '--bg-navbar-header': '#353346',
    '--bg-card': '#2b293a',
    '--text-title-color': '#b8b5ce',
    '--text-subtitle-color': '#b8b5ce',
    '--text-primary': '#20ace8',
    '--text-white': '#ffffff',
    '--text-a6a6a6': '#b8b5ce',
    '--text-title-zone': '#ffffff',
    '--text-color-black': '#ffffff',
    '--text-color': '#b8b5ce',
    '--btn-custom': '#48465c',
    '--btn-custom-border': 'none',
    '--bg-table-head': '#1f1d2b',
    '--bg-wind-start': '#48465c',


};

export {light, dark};
