import React, {useEffect, useState} from "react";
import ButtonCustom from "../Button";
import {Button, message, Typography} from "antd";
import {ReactComponent as Tick} from "../../assets/svg/save.svg";
import {observer} from "mobx-react";
import {useStore} from "../../mobxStores/stores";
import './styles.scss'

const SetTemp = (
    {
        currentTemp,
        newTemp,
        type,
        mac,
        isFault,
        isActive,
        isSnowEnable,
        isWindOn,
        isSchedOn
    }
) => {

    const {zoneStore: {updateDevice}, authStore: {userUnit}} = useStore();

    const [temp, setTemp] = useState(parseInt(currentTemp));

    const [showBtn, setShowBtn] = useState(false);

    const handleClick = (num) => {
        if (isSchedOn) {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isWindOn) {
            message.warning('Wind Timer is active');
            return;
        }
        if (isSnowEnable) {
            message.warning('Snow sensor is enable');
            return;
        }

        setTemp(prevState => prevState + num)
        setShowBtn(true);

    }

    const confirmTemp = () => {
        if (temp < 25 || temp > 999) {
            message.warn('Temp must be between 25 - 999');
            return;
        }
        let payload = {};
        if (type === 'blower') {
            payload = {
                mac,
                set_temp: temp
            }
        }
        if (type === 'switch') {
            payload = {
                mac,
                set_temp: temp
            }
        }
        if (type === 'electrical') {
            payload = {
                mac,
                e_set_temp: temp
            }
        }
        updateDevice(payload)
        message.success('Set Device/s Temperature To ' + temp);
        setShowBtn(false)
    }

    useEffect(() => {
        setTemp(newTemp)
    }, [newTemp])

    return (<div className="position-relative">

        <Typography className="Body text-000 d-flex justify-content-between align-items-center">

            <ButtonCustom
                disabled={isFault || !isActive}
                type="-"
                onClick=
                    {
                        () => handleClick(-1)
                    }
            />

            <Typography.Text
                className="set-temp"
            >
                {
                    isActive && !isFault ? temp + '°' + userUnit.toUpperCase() : '_'
                }
            </Typography.Text>

            <ButtonCustom
                disabled={isFault || !isActive}
                type="+"
                onClick=
                    {
                        () => handleClick(1)
                    }
            />

        </Typography>

        {
            showBtn && <Button
                onClick={confirmTemp}
                className="btnSetTemp position-absolute"
                style=
                    {
                        {}
                    }
            >
                <Tick style={{width: '20px', height: 'auto'}} fill="#3ccab0"/>
            </Button>

        }

    </div>);
}

export default observer(SetTemp);
