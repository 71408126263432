import {Card, Typography, Tabs, Button} from "antd";
import GarphData from "../GarphData";
import './styles.scss';
import AuditTrailHistory from "../AuditTrialHistory";

const {TabPane} = Tabs;

const callback = (key) => {
    console.log(key);
}

const DataLogging = () => {
    return (
        <>
            <Typography.Title
                className="mb-20"
                level={5}
            >

                <span>Data Logging</span>

                <Button className="float-right camActive">
                    <span>Cam Active</span>
                </Button>

            </Typography.Title>

            <Card
                bordered={false}
                bodyStyle={{padding: '10px 0 0'}}
            >

                <Tabs
                    className="text-3f3f3f"
                    defaultActiveKey="1"
                    onChange={callback}
                >

                    <TabPane
                        style={{padding: '4px 24px 23px 27px'}}
                        tab={
                            <span style={{padding: '14px 24px 15px 25px'}}>Graph & Data</span>
                        }
                        key="1"
                    >
                        <GarphData/>
                    </TabPane>

                    <TabPane
                        className="p-2"
                        tab={
                            <span style={{padding: '14px 24px 15px 25px'}}>Audit Trail & History</span>
                        }
                        key="2"
                    >
                        <AuditTrailHistory/>
                    </TabPane>

                </Tabs>

            </Card>
        </>
    );
}

export default DataLogging;
