import {makeObservable, observable, action, computed, toJS} from "mobx";
import {addSchedule, deleteSchedule, fetchSchedules, updateSchedule} from "../services/schedules";
import {authStore} from "./auth.store";
import moment from "moment";
import {convertCelsiusToFahrenheit, convertFahrenheitToCelsius} from "../utils/helper";

export class ScheduleStore {
    loading: boolean = false;
    schedules: any = [];
    schedule: any = {};
    program: any = [];
    filters: any = {};
    selectedDevices: any = [];

    constructor() {
        makeObservable(this, {
            schedules: observable,
            schedule: observable,
            loading: observable,
            program: observable,
            filters: observable,
            selectedDevices: observable,
            computedSchedules: computed,
            fetchSchedules: action,
            addSchedule: action,
            deleteSchedule: action,
            deleteSelectedSchedule: action,
            findProgram: action,
            programComputed: computed
        });
    }

    clearFilter = () => {
        this.filters = {};
    }
    setFilter = (filtered: any, type: string) => {
        if (type === 'start_date') {
            this.filters = {
                ...this.filters,
                start_date: filtered
            };
        }

        if (type === 'end_date') {
            this.filters = {
                ...this.filters,
                end_date: filtered
            };
        }

        if (type === 'device') {
            this.filters = {
                ...this.filters,
                device: filtered
            };
        }

    }

    get computedSchedules() {

        let filtered_schedules = [];

        if (authStore.userUnit === 'f') {
            filtered_schedules = this.schedules.map((schedule: any, index: number) => {
                return {
                    ...schedule,
                    key: schedule.program_id,
                    start_date: schedule.start_date,
                    end_date: schedule.end_date,
                    device_ids: schedule.device_ids.map((item: any) => item.label).toString(),
                    created_by: schedule.created_by,
                    set_temp: convertCelsiusToFahrenheit(schedule.set_temp),
                    note: schedule.note
                }
            })
        } else {
            filtered_schedules = this.schedules.map((schedule: any, index: number) => {
                return {
                    ...schedule,
                    key: schedule.program_id,
                    start_date: schedule.start_date,
                    end_date: schedule.end_date,
                    device_ids: schedule.device_ids.map((item: any) => item.label).toString(),
                    created_by: schedule.created_by,
                    set_temp: schedule.set_temp,
                    note: schedule.note
                }
            })
        }

        if (this.filters) {
            if (this.filters.device) {
                filtered_schedules = filtered_schedules.filter((item: any) => {
                    return item.device_ids.includes(this.filters.device)
                })
            }

            if (this.filters.start_date && this.filters.end_date) {
                filtered_schedules = filtered_schedules.filter((item: any) => {
                    if (
                        moment(this.filters.start_date).isBetween(item.start_date, item.end_date)
                        &&
                        moment(this.filters.end_date).isBetween(item.start_date, item.end_date)
                    ) {
                        return item
                    }
                })
            }
        }

        return filtered_schedules;
    }

    fetchSchedules = async (id: string) => {
        this.loading = true;
        this.schedules = [];
        try {
            const res = await fetchSchedules({id: id});
            console.log('schedules', res.data);
            this.schedules = res.data;
        } catch (e) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    deleteSchedule = async (id: string) => {
        this.loading = true;
        try {
            await deleteSchedule({schedule_id: [id]})
            this.schedules = this.schedules.filter((item: any) => item.program_id !== id);
            console.log('this.computedSchedule', this.schedules);
        } catch (e) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    deleteSelectedSchedule = async (ids: any) => {
        this.loading = true;
        try {
            if (ids && ids.length > 0) {
                await deleteSchedule({schedule_id: ids})
                ids.map(async (id: any) => {
                    this.schedules = this.schedules.filter((item: any) => item.program_id !== id);
                    console.log('this.computedSchedule', this.schedules);
                })
            }
        } catch (e) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    addSchedule = async (payload: any) => {
        this.loading = true;
        try {
            if (authStore.userUnit === 'f') {
                payload.set_temp = convertFahrenheitToCelsius(payload.set_temp);
            }
            console.log('payload', payload)
            const res = await addSchedule(payload);
            const newSched = res.data;
            this.schedules = [newSched, ...this.schedules];
            this.loading = false;
        } catch (e) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    updateSchedule = async (payload: any) => {
        this.loading = true;
        try {
            if (authStore.userUnit === 'f') {
                payload.set_temp = convertFahrenheitToCelsius(payload.set_temp);
            }
            console.log('payload', payload)
            const res = await updateSchedule(payload);
            const updatedSched = res.data;
            let index = this.schedules.findIndex((item: any) => item.program_id === payload.program_id);
            this.schedules = [
                ...this.schedules.splice(0, index),
                Object.assign({}, this.schedules[index], updatedSched),
                ...this.schedules.splice(index + 1),
            ]
            this.loading = false;
        } catch (e) {
            this.loading = false;
        } finally {
            this.loading = false;
        }
    }

    findProgram = (record: any) => {
        this.loading = true;
        this.program = {};
        this.selectedDevices = [];
        if (record && record.device_ids) {
            this.program = this.schedules.find((item: any) => item.program_id == record.program_id);
            this.selectedDevices = this.program.device_ids;
        }
        this.loading = false;
    }

    get programComputed() {
        if (authStore.userUnit === 'f') {
            return {
                ...this.program,
                set_temp: convertCelsiusToFahrenheit(this.program.set_temp)
            }
        }
        return this.program;
    }


}


export const scheduleStore = new ScheduleStore();
