import {useApi as RestApi} from "../utils/API";
import api from '../utils/EndPoints'
import {authStore} from "../mobxStores/auth.store";

const {get, post} = RestApi();
const {updateDeviceUrl} = api
const fetchDevices = async (payload) => {
    try {
        return await get('/get-' + localStorage.getItem('platform') + '/' + payload.id);
    } catch (error) {
        throw error;
    }
}
const fetchDevice = async (payload) => {
    try {
        if (localStorage.getItem('platform') === 'switches') {
            return await get('/get-switch/' + payload.id);
        } else {
            return await get('/get-device/' + payload.id);
        }
    } catch (error) {
        throw error;
    }
};

const fetchReports = async (payload) => {
    try {
        return await get('/get-reports?' + payload);
    } catch (error) {
        throw error;
    }
}

const fetchAudits = async (payload) => {
    try {
        return await get('/user-activity?mac=' + payload.mac + '&page=' + payload.page + '&entities=' + payload.entities);
    } catch (error) {
        throw error;
    }
}

const updateDevice = async (payload) => {
    try {
        const url = authStore.getPlatform() === 'switches' ? 'switch' : 'blower';
        console.log({url});
        console.log(updateDeviceUrl + url)
        console.log(payload)
        return await post(updateDeviceUrl + url, payload)
    } catch (error) {
        throw error
    }
}
export {fetchDevices, fetchDevice, fetchReports, updateDevice, fetchAudits};
