import React from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../mobxStores/stores";
import {Card, Typography} from "antd";
import {ReactComponent as Lightning} from '../../assets/svg/Lightning.svg';
import './styles.scss';

const Energy = () => {
    const {zoneStore: {compoutedEnergy}, authStore: {userSpeed}} = useStore();

    const {Title, Text, Paragraph} = Typography;

    return (
        <div className="Energy">

            <Text className="text-6d6d6d title d-block text-uppercase">Energy</Text>

            <Card
                className="Card border-0"
                bodyStyle={{padding: '23px 0px 24px 21px'}}
                bordered={false}
            >
                <Typography className="Body text-000 d-flex justify-content-center align-items-center">

                    <Lightning/>

                    <div className="Content">

                        <Title className="title">{compoutedEnergy} {userSpeed.toUpperCase()}/h</Title>

                        <Paragraph className="paragraph text-979797">
                            Total Energy Consumption
                        </Paragraph>

                    </div>

                </Typography>
            </Card>

        </div>
    );
}

export default observer(Energy);
