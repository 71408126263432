import React from "react";
import {Card, Divider, Space, Switch, Typography} from "antd";
import {authStore} from "../../../mobxStores/auth.store";
import {observer} from "mobx-react";
import classNames from "classnames";
import './styles.scss';

const OtherSettings = () => {

    const cardBodyStyle = {
        padding: '23px 17px 22px 20px',
        minWidth: 275
    }

    const {Title, Text} = Typography

    const {
        updateUser,
        setUserMode,
        setUserLayout,
        userMode,
        userLayout
    } = authStore;

    const updateUserData = async (payload, type) => {
        try {
            if (type === 'dark') {
                setUserMode(payload.dark_mode);
            } else if (type === 'layout') {
                setUserLayout(payload.vertical_layout);
            }
            await updateUser(payload);
        } catch (error) {
            console.log('error', error)
        }
    }

    const toggleTheme = (isChecked) => {
        if (isChecked) {
            updateUserData({dark_mode: 1}, 'dark');
        } else {
            updateUserData({dark_mode: 0}, 'dark');
        }
    };

    const toggleLayout = (isChecked) => {
        if (isChecked) {
            updateUserData({vertical_layout: 1}, 'layout');
        } else {
            updateUserData({vertical_layout: 0}, 'layout');
        }
    }
    return (
        <div className="OtherSettings">

            <Title className="text-uppercase text-3f3f3f title">Other Settings</Title>

            <Card
                className="Card"
                bordered={false}
                bodyStyle={cardBodyStyle}
            >

                <div className="CardContent">

                    <Space className="d-flex justify-content-between align-items-center">

                        <Text>Dark Mode</Text>

                        <div>

                            <Switch
                                style={{transform: 'scale(.773)'}}
                                title="Dark Mode"
                                checked={userMode === 1 ? 'checked' : null}
                                onChange={toggleTheme}
                            />

                            <span
                                className=
                                    {
                                        classNames([{
                                            'text-uppercase': true,
                                            'text-979797': userMode == 0 ? true : false,
                                            'text-20ace8': userMode === 1 ? true : false,
                                        }])
                                    }
                                style={{marginLeft: 11}}
                            >
                                {
                                    userMode === 1 ? 'On' : 'Off'
                                }
                            </span>

                        </div>

                    </Space>

                    <Divider className="mt-19 mb-21" />

                    <Space className="d-flex justify-content-between align-items-center">

                        <Text>Vertical Layout</Text>

                        <div>

                            <Switch
                                style={{transform: 'scale(.773)'}}
                                title="Vertical Layout"
                                checked={userLayout === 1 ? 'checked' : null}
                                onChange={toggleLayout}
                            />

                            <span
                                className=
                                    {
                                        classNames([{
                                            'ml-11': true,
                                            'text-uppercase': true,
                                            'text-20ace8': userLayout === 1 ? true : false,
                                            'text-979797': userLayout == 0 ? true : false,
                                        }])
                                    }
                            >
                                {
                                    userLayout === 1 ? 'On' : 'Off'
                                }
                            </span>

                        </div>

                    </Space>

                </div>

            </Card>

        </div>
    );
}

export default observer(OtherSettings);
