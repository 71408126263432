import React, {useState} from 'react';
import {Button, Card, message, Typography} from "antd";
import {useParams} from 'react-router-dom';
import Countdown from "antd/es/statistic/Countdown";
import {ReactComponent as Wind} from '../../assets/svg/Wind.svg';
import {zoneStore} from "../../mobxStores/zones.store";
import {observer} from "mobx-react";
import moment from "moment";
import './styles.scss';
import Loader from "../Loader";

const WindFactor = () => {

    const cardBodyStyle = {padding: '17px 0px 17px 21px'};

    const {id} = useParams();

    const [wind, setWind] = useState(0);

    // const [deadline, setDeadline] = useState(0);

    const onEnd = async () => {
        const payload = {
            zoneId: id,
            status: 0,
            startDate: 0,
            numberOfHours: null
        };
        // await zoneStore.windTimer(payload);
    }
    const {Text, Paragraph} = Typography;

    const handleWind = async (val) => {

        setWind(val);

        // setDeadline(Date.now() + 1000 * 60 * 60 * val);

        const payload = val > 0 ? ({
                zone_id: id,
                status: 1,
                end_date: moment().add(val, 'hour').valueOf(),
                number_of_hours: val
            }) :
            ({
                zone_id: id,
                status: 0,
                end_date: 0,
                number_of_hours: 0,

            })

        await zoneStore.windTimer(payload);
        if (payload.status === 0) {
            message.success('Wind Timer is off');
        }
    }
    console.log('getDeadlineWind', zoneStore.getDeadlineWind())
    console.log('zoneStore.getWind()', zoneStore.getWind())

    return (
        <div className="WindTimer">

            <Text className="text-6d6d6d title d-block text-uppercase">Wind Timer</Text>

            <Card className="Card border-0" bodyStyle={cardBodyStyle}>

                <Typography className="Body text-color-black d-flex justify-content-center align-items-center">

                    <Wind/>

                    <div className="wind_countdown">

                        <Countdown
                            value={zoneStore.getDeadlineWind()}
                            // value={Date.now() + 1000 * 5}
                            onFinish={onEnd}
                            valueStyle={{fontSize: 20, fontWeight: "normal", color: 'var(--text-color-black)'}}
                            className="countdown text-color-black" format="HH:mm:ss"
                        />

                        <Paragraph className="text-979797 m-0">Hour : Min : Sec</Paragraph>

                    </div>
                    {
                        zoneStore.loadingWind && zoneStore.getWind() === 0 ? <Loader/>
                            :
                            zoneStore.getWind() === 0 &&
                            <div className="btns d-flex justify-content-center align-items-center flex-column">

                                <Button onClick={() => handleWind(12)} className="bg-pri">12 Hrs</Button>

                                <Button onClick={() => handleWind(24)} className="bg-sec text-979797">24 Hrs</Button>

                            </div>
                    }
                    {
                        zoneStore.loadingWind && zoneStore.getWind() > 0 ? <Loader/> :
                            zoneStore.getWind() > 0 &&
                            <div className="windStart" onClick={() => handleWind(0)}>
                                <span className="text-20ace8 text-20px">{zoneStore.getWind()}</span>
                                <span className="text-3f3f3f text-14px">hours</span>
                            </div>
                    }
                </Typography>

            </Card>

        </div>
    );
}

export default observer(WindFactor);
