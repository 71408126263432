import React from "react";

import {AuthStore, authStore} from "./auth.store";
import {MobXProviderContext} from "mobx-react";
import {ZoneStore, zoneStore} from "./zones.store";
import {deviceStore, DeviceStore} from "./devices.store";
import {scheduleStore, ScheduleStore} from "./schedules.store";

type MobxStores = {
    authStore: AuthStore;
    zoneStore: ZoneStore;
    deviceStore: DeviceStore;
    scheduleStore: ScheduleStore
};

export function createStores() {
    return {
        authStore: authStore,
        zoneStore: zoneStore,
        deviceStore: deviceStore,
        scheduleStore: scheduleStore
    };
}

/**
 * @return MobxStores
 */
export const useStore = () => {
    const store = React.useContext(MobXProviderContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error("useStore must be used within a StoreProvider.");
    }
    return store;
};
