import React, {useCallback, useRef} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {observer} from 'mobx-react';
import Highcharts from 'highcharts/highstock';
import {Button, Typography} from 'antd';
import './styles.scss';

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

const HighChart = ({category, reports}) => {
    const ref = useRef();
    /**
     * Options to draw in Charts
     */

    const options = {
        chart: {
            type: 'area',
        },
        credits: {
            enabled: false,
        },
        title: {
            text:
                'Graph and Data ' +
                category.charAt(0).toUpperCase() +
                category.slice(1),
        },
        subtitle: {
            text: null,
            align: 'center',
            verticalAlign: 'bottom',
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            title: {
                text: category.toUpperCase(),
            },
        },
        series: reports,
        exporting: {
            allowHTML: true,
        },
    };

    const showTableInSubtitle = useCallback(() => {
        var table = ref.current?.chart.dataTableDiv;
        if (table) {
            // Apply styles inline because stylesheets are not passed to the exported SVG
            Highcharts.css(table.querySelector('table'), {
                'border-collapse': 'collapse',
                'border-spacing': 0,
                background: 'white',
                'min-width': '100%',
                'font-family': 'sans-serif',
                'font-size': '14px',
                alignSelf: 'center',
            });

            [].forEach.call(
                table.querySelectorAll('td, th, caption'),
                function (elem) {
                    Highcharts.css(elem, {
                        border: '1px solid silver',
                        padding: '0.5em',
                    });
                },
            );

            Highcharts.css(table.querySelector('caption'), {
                'border-bottom': 'none',
                'font-size': '1.1em',
                'font-weight': 'bold',
            });

            [].forEach.call(
                table.querySelectorAll('caption, tr'),
                function (elem, i) {
                    if (i % 2) {
                        Highcharts.css(elem, {
                            background: '#f8f8f8',
                        });
                    }
                },
            );

            // Add the table as the subtitle to make it part of the export
            ref.current.chart.setTitle(null, {
                text: table.innerHTML,
                useHTML: true,
            });
            if (table.parentNode) {
                table.parentNode.removeChild(table);
            }
            delete ref.current.chart.dataTableDiv;
        }
    }, [ref]);

    const onDownloadClick = () => {
        const {chart, container} = ref.current;

        const isDataVisible = !!chart.dataTableDiv;
        chart.viewData()
        const sourceHeight =
            chart.dataTableDiv?.clientHeight + container.current.clientHeight;
        const sourceWidth = container.current.clientWidth;

        showTableInSubtitle();
        chart.exportChart({
            type: 'application/pdf',
            sourceHeight,
            sourceWidth,
        });
        chart.setSubtitle({text: null, useHTML: false});
        chart.hideData();

        if (isDataVisible) chart.viewData();
    };

    return (
        <div>
            <HighchartsReact
                ref={ref}
                highcharts={Highcharts}
                options={options}
                allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                containerProps={{className: 'chartContainer'}}
            />
            <Typography.Title
                className='text-center'
                style={{marginTop: 40, marginBottom: 0, lineHeight: '0px'}}
            >
                <Button
                    style={{lineHeight: '18px', height: 18, fontSize: 14}}
                    onClick={onDownloadClick}
                    className='border-0 text-20ace8 bg-none p-0 font-weight-bold'
                >
                    Download Detailed Log
                </Button>
            </Typography.Title>
        </div>
    );
};

export default observer(HighChart);
