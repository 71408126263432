import React, {useState} from "react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {useStore} from "../../../../mobxStores/stores";

import {Button, Divider, message, Typography} from "antd";

import {ReactComponent as DeviceOn} from "../../../../assets/svg/device-on.svg";
import {ReactComponent as DeviceOff} from "../../../../assets/svg/device-off.svg";
import {ReactComponent as VideoCameraSlash} from "../../../../assets/svg/VideoCameraSlash.svg";
import {ReactComponent as PowerOff} from "../../../../assets/svg/Snowflake2.svg";

import {useMediaQuery} from "../../../../utils/helper";

import SetTemp from "../../../SetTemp";
import Loader from "../../../Loader";
import RssIcon from "../../../RssIcon";
import FanIcon from "../../../FanIcon";
import classNames from 'classnames'
import {authStore} from "../../../../mobxStores/auth.store";

const Details = ({device, updateDevice, isFaultGrd, isFaultHPLP, isFaultBMS, type}) => {

    const {
        device_mac,
        device_active,
        e_switch_status,
        snow_sensor,
        e_snow_sensor,
        snow_detection,
        e_snow_detection,
        set_temp,
        e_set_temp,
        enclosure_temp,
        e_enclosure_temp,
        gas_consumption,
        e_gas_consumption,
        energy_consumption,
        e_energy_consumption,
        wind,
        schedule,
        e_schedule,
        heater_temperature,
        e_heater_temperature,
        fan_status,
        switch_status
    } = device;

    const [width] = useMediaQuery();

    const {
        authStore: {userUnit, userLayout, userSpeed},
        zoneStore: {loadingDevice}
    } = useStore();

    const isActive = device_active === 1;

    const isSwitchActive = switch_status === 1;

    const isWindOn = wind === 1;

    const isSchedOn = schedule === 1;

    const isElectricalSchedOn = e_schedule === 1;

    const isSnowEnable = snow_sensor === 1;

    const isSnowDetection = snow_detection === 1;

    const isActiveElectrical = e_switch_status === 1;

    const isSnowEnableElectrical = e_snow_sensor === 1;

    const isSnowDetectionElectrical = e_snow_detection === 1;


    const fanStatus = fan_status === 1;

    const deviceOnOff = () => {
        if ((isSchedOn && type === 'blower') || (isSchedOn && type === 'switch')) {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isElectricalSchedOn && type === 'electrical') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isWindOn) {
            message.warning('Wind Timer is active')
            return;
        }
        if (type === 'blower') {
            if (isSnowEnable) {
                message.warning('Snow sensor is enabled');
                return;
            }
            updateDevice(device, {
                mac: device_mac,
                device_active: isActive ? 0 : 1,
            })
        }

        if (type === 'switch') {
            if (isSnowEnable) {
                message.warning('Snow sensor is enabled');
                return;
            }
            updateDevice(device, {
                mac: device_mac,
                switch_status: isSwitchActive ? 0 : 1,
            })
        }

        if (type === 'electrical') {
            if (isSnowEnableElectrical) {
                message.warning('Snow sensor is enabled');
                return;
            }
            updateDevice(device, {
                mac: device_mac,
                e_switch_status: isActiveElectrical ? 0 : 1
            })
        }
    }

    const snowSensorOnOff = () => {

        if (isSchedOn && type === 'blower') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isSchedOn && type === 'switch') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isElectricalSchedOn && type === 'electrical') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isWindOn) {
            message.warning('Wind Timer is active')
            return;
        }

        if (type === 'blower') updateDevice(device, {
            mac: device_mac,
            snow_sensor: isSnowEnable ? 0 : 1,
            snow_detection: isSnowEnable ? 0 : 1,
            device_active: isActive ? 1 : isSnowEnable ? 0 : 1
        })

        if (type === 'switch') updateDevice(device, {
            mac: device_mac,
            snow_sensor: isSnowEnable ? 0 : 1,
            snow_detection: isSnowEnable ? 0 : 1,
            switch_status: isSwitchActive ? 1 : isSnowEnable ? 0 : 1
        })

        if (type === 'electrical') updateDevice(device, {
            mac: device_mac,
            e_snow_sensor: isSnowEnableElectrical ? 0 : 1,
            e_snow_detection: isSnowEnableElectrical ? 0 : 1,
            e_switch_status: isActiveElectrical ? 1 : isSnowEnableElectrical ? 0 : 1
        })
    }

    const SetTemperature = () => {
        return (
            <>
                <Typography.Paragraph className="text-uppercase text-979797 DeviceTitle">
                    set temperature
                </Typography.Paragraph>
                {
                    type === 'electrical' && (
                        <SetTemp
                            isFault={isFaultGrd}
                            isActive={isActiveElectrical}
                            newTemp={e_set_temp}
                            currentTemp={e_set_temp}
                            type={type}
                            mac={device_mac}
                            isSnowEnable={isSnowEnableElectrical}
                            isWindOn={isWindOn}
                            isSchedOn={isElectricalSchedOn}
                        />
                    )
                }
                {
                    type === 'blower' && (
                        <SetTemp
                            isFault={isFaultGrd || isFaultHPLP || isFaultBMS}
                            isActive={isActive}
                            newTemp={set_temp}
                            currentTemp={set_temp}
                            type={type}
                            mac={device_mac}
                            isSnowEnable={isSnowEnable}
                            isWindOn={isWindOn}
                            isSchedOn={isSchedOn}
                        />
                    )
                }
                {
                    type === 'switch' && (
                        <SetTemp
                            isFault={isFaultGrd}
                            isActive={isSwitchActive}
                            newTemp={set_temp}
                            currentTemp={set_temp}
                            type={type}
                            mac={device_mac}
                            isSnowEnable={isSnowEnable}
                            isWindOn={isWindOn}
                            isSchedOn={isSchedOn}
                        />
                    )
                }
            </>
        )
    }

    const ButtonSnowSensor = () => {
        return (
            <>
                {
                    type === 'blower' &&
                    <Button
                        onClick={snowSensorOnOff}
                        className={classNames({
                            'BtnSnowSensor': true,
                            'BtnActive': isSnowEnable && !isWindOn && !isSchedOn
                        })}
                        disabled={isFaultGrd || isFaultHPLP || isFaultBMS || isSchedOn || isWindOn}>

                        <PowerOff fill={(isSnowDetection || isSnowEnable) && !isWindOn && !isSchedOn
                            ? '#fff' : '#9a9a9a'}/>

                        <span>Snow Sensor</span>

                    </Button>
                }
                {
                    type === 'switch' &&
                    <Button
                        onClick={snowSensorOnOff}
                        className={classNames({
                            'BtnSnowSensor': true,
                            'BtnActive': isSnowEnable && !isWindOn && !isSchedOn
                        })}
                        disabled={isFaultGrd || isSchedOn || isWindOn}>

                        <PowerOff fill={isSnowDetection && isSnowEnable && !isWindOn && !isSchedOn
                            ? '#fff' : '#9a9a9a'}/>

                        <span>Snow Sensor</span>

                    </Button>
                }
                {
                    type === 'electrical' &&
                    <Button
                        onClick={snowSensorOnOff}
                        className={classNames({
                            'BtnSnowSensor': true,
                            'BtnActive': isSnowEnableElectrical && !isWindOn && !isElectricalSchedOn
                        })}
                        disabled={isFaultGrd || isWindOn || isElectricalSchedOn}>

                        <PowerOff
                            fill={(isSnowEnableElectrical || isSnowDetectionElectrical) && !isWindOn && !isElectricalSchedOn ? '#fff' : '#9a9a9a'}/>

                        <span>Snow Sensor</span>

                    </Button>
                }
            </>
        )
    }

    const ButtonVan = () => {
        return (
            <>
                {
                    type === 'blower' &&
                    <Button
                        onClick={handleFan}
                        className={classNames({
                            'BtnSnowSensor': true,
                            'BtnFanWidth': true,
                            'BtnActive': fanStatus,
                        })}>
                        <FanIcon
                            fill={fanStatus ? '#fff' : '#9a9a9a'}
                            stroke={fanStatus ? '#208EE8' : '#fff'}
                        />
                        <span>Fan Only</span>
                    </Button>
                }
            </>
        )
    }

    const ButtonOnOff = ({className}) => {
        return (
            <>
                {
                    type === 'blower' &&
                    <Button
                        disabled={isFaultGrd || isFaultHPLP || isFaultBMS}
                        className={classNames('border-0 p-0 bg-none', className)}
                        onClick={deviceOnOff}
                    >
                        {
                            isActive && !isFaultGrd && !isFaultHPLP && !isFaultBMS ? <DeviceOn/> : <DeviceOff/>
                        }
                    </Button>
                }

                {
                    type === 'switch' &&
                    <Button
                        disabled={isFaultGrd}
                        className={classNames('border-0 p-0 bg-none', className)}
                        onClick={deviceOnOff}
                    >
                        {
                            isSwitchActive && !isFaultGrd ? <DeviceOn/> : <DeviceOff/>
                        }
                    </Button>
                }

                {
                    type === 'electrical' &&
                    <Button
                        disabled={isFaultGrd}
                        className={classNames('border-0 p-0 bg-none', className)}
                        onClick={deviceOnOff}
                    >
                        {
                            isActiveElectrical && !isFaultGrd ? <DeviceOn/> : <DeviceOff/>
                        }
                    </Button>
                }
            </>
        )
    }

    const CurrentTemp = () => {
        return (
            <div className="d-flex flex-column justify-content-start m-0">
                <div className="text-979797 Current text-uppercase">Current</div>
                {
                    type === 'blower' && <div
                        className={
                            classNames({
                                'Temp': true,
                                'text-000': isActive && !isFaultGrd && !isFaultHPLP && !isFaultBMS,
                            })
                        }
                    >
                        {
                            isActive && !isFaultGrd && !isFaultHPLP && !isFaultBMS ? heater_temperature + '°' + userUnit.toUpperCase() : '_'
                        }
                    </div>
                }

                {
                    type === 'switch' && <div
                        className={
                            classNames({
                                'Temp': true,
                                'text-000': isSwitchActive && !isFaultGrd,
                            })
                        }
                    >
                        {
                            isSwitchActive && !isFaultGrd ? heater_temperature + '°' + userUnit.toUpperCase() : '_'
                        }
                    </div>
                }

                {
                    type === 'electrical' && <div
                        className={
                            classNames({
                                'Temp': true,
                                'text-000': isActiveElectrical && !isFaultGrd,
                            })
                        }
                    >
                        {
                            isActiveElectrical && !isFaultGrd ? e_heater_temperature + '°' + userUnit.toUpperCase() : '_'
                        }
                    </div>
                }
            </div>
        );
    }

    const ButtonRss = ({className}) => {
        return (
            <>
                {
                    type === 'blower' &&
                    <Button
                        disabled={isFaultGrd || isFaultHPLP || isFaultBMS || isWindOn || isSchedOn || !isActive}
                        className={classNames('p-0 border-0 bg-none m-0 btn-device', className)}
                    >
                        <RssIcon
                            fill={isFaultGrd || isFaultHPLP || isFaultBMS || isWindOn || isSchedOn || !isActive ? '#CCC' : '#3ccab0'}/>
                    </Button>
                }
                {
                    type === 'switch' &&
                    <Button
                        disabled={isFaultGrd || isWindOn || isSchedOn || !isSwitchActive}
                        className={classNames('p-0 border-0 bg-none m-0 btn-device', className)}
                    >
                        <RssIcon fill={isFaultGrd || isWindOn || isSchedOn || !isSwitchActive ? '#CCC' : '#3ccab0'}/>
                    </Button>
                }
                {
                    type === 'electrical' &&
                    <Button
                        disabled={isFaultGrd || isWindOn || isElectricalSchedOn || !isActiveElectrical}
                        className={classNames('p-0 border-0 bg-none m-0 btn-device', className)}
                    >
                        <RssIcon
                            fill={isFaultGrd || isWindOn || isElectricalSchedOn || !isActiveElectrical ? '#CCC' : '#3ccab0'}/>
                    </Button>
                }
            </>
        )
    }

    const ButtonVideo = ({className}) => {
        return (
            <>
                {
                    type === 'blower' &&
                    <Button
                        disabled={isFaultGrd || isFaultHPLP || isFaultBMS || isWindOn || isSchedOn || !isActive}

                        className={
                            classNames('p-0 border-0 bg-none m-0', className)
                        }
                    >
                        <VideoCameraSlash/>
                    </Button>
                }

                {
                    type === 'switch' &&
                    <Button
                        disabled={isFaultGrd || isWindOn || isSchedOn || !isSwitchActive}

                        className={
                            classNames('p-0 border-0 bg-none m-0', className)
                        }
                    >
                        <VideoCameraSlash/>
                    </Button>
                }

                {
                    type === 'electrical' &&
                    <Button
                        disabled={isFaultGrd || isWindOn || isElectricalSchedOn || !isActiveElectrical}

                        className={
                            classNames('p-0 border-0 bg-none m-0', className)
                        }
                    >
                        <VideoCameraSlash/>
                    </Button>
                }
            </>
        )
    }

    const ButtonDetails = () => {
        return (
            <Button className="border-0 btn-outline- BtnDetails">
                <Link to={'/dashboard/' + authStore.getPlatform() + '/' + device_mac + '/details'}>View Details</Link>
            </Button>
        )
    }

    const RenderButtons = ({className}) => {
        return (
            <div className={className}>
                <ButtonSnowSensor/>
                <ButtonVan/>
            </div>
        )
    }

    const RenderComponent = ({title, value, electrical_value, unit, className}) => {
        return (
            <div className={classNames("temp_device", className)}>
                <div className="text-uppercase text-979797 DeviceTitle">{title}</div>
                {
                    type === 'electrical' &&
                    <span
                        className={classNames({
                            'enc_temp': true,
                            'text-000': isActiveElectrical && !isFaultGrd,

                        })}
                    >
                        {
                            isActiveElectrical && !isFaultGrd && electrical_value ? electrical_value + unit : '_'
                        }
                    </span>
                }
                {
                    type === 'blower' &&
                    <span
                        className={classNames({
                            'enc_temp': true,
                            'text-000': isActive && !isFaultGrd && !isFaultHPLP && !isFaultBMS,
                        })}
                    >
                        {
                            isActive && !isFaultGrd && !isFaultHPLP && !isFaultBMS && value ? value + unit : '_'
                        }
                    </span>
                }
                {
                    type === 'switch' &&
                    <span
                        className={classNames({
                            'enc_temp': true,
                            'text-000': isSwitchActive && !isFaultGrd,
                        })}
                    >
                        {
                            isSwitchActive && !isFaultGrd && value ? value + unit : '_'
                        }
                    </span>
                }
            </div>
        )
    }

    const handleFan = () => {
        let payload = {};
        if (type === 'blower') {
            payload = {
                mac: device_mac,
                fan_status: !fanStatus ? 1 : 0
            }
        }
        updateDevice(device, payload)
        if (!fanStatus) message.success('Fan is Enabled');
        else message.success('Fan is Disabled');

    }

    if (loadingDevice) return <Loader/>

    return width < 1270 || userLayout === 0 ?
        (<div
            className={classNames('horizontalPadding', !isActive && 'DeviceDisconneted')}
        >

            <div className="d-flex justify-content-between DeviceSec1">

                <div className="d-flex justify-content-center align-items-center DeviceSec1_1">

                    <ButtonOnOff/>

                    <CurrentTemp/>

                </div>

                <div className="d-flex justify-content-center align-items-center DeviceSec1_2">

                    <ButtonRss/>

                    <ButtonVideo/>

                </div>

            </div>

            <RenderButtons className="DeviceSec2 d-flex justify-content-start align-items-center"/>

            <SetTemperature/>

            <Divider className="bg-divider mt-18 mb-16"/>

            <div className="CardFooter d-flex justify-content-between align-items-center">

                <RenderComponent
                    title={'Enclosure temp'}
                    value={enclosure_temp}
                    electrical_value={e_enclosure_temp}
                    unit={' °' + userUnit.toUpperCase()}
                />

                <RenderComponent
                    title={'Fuel'}
                    value={gas_consumption}
                    electrical_value={e_gas_consumption}
                    unit={' ft3'}
                />

                <RenderComponent
                    title={'Energy'}
                    value={energy_consumption}
                    electrical_value={e_energy_consumption}
                    unit={' ' + userSpeed.toUpperCase() + '/h'}
                />

            </div>

            <ButtonDetails/>

        </div>)
        :
        <div className="verticalPadding">

            <div className="d-flex justify-content-between align-items-center DeviceSec1_2">

                <ButtonRss className="ButtonRssVertical h-22"/>

                <ButtonVideo className="BtnVideoVertical"/>

                <ButtonDetails/>

            </div>

            <div className="d-flex justify-content-between align-items-center DeviceSec1">

                <ButtonOnOff className="mr-35"/>

                <div className="d-flex justify-content-center align-items-center DeviceSec1_1 width-67">
                    <CurrentTemp/>
                </div>

                <RenderComponent
                    title={'Enclosure temp'}
                    value={enclosure_temp}
                    className="enclosure_temp_vertical"
                    electrical_value={e_enclosure_temp}
                    unit={' °' + userUnit.toUpperCase()}
                />

                <RenderComponent
                    title={'Fuel'}
                    value={gas_consumption}
                    className="fuel_vertical"
                    electrical_value={e_gas_consumption}
                    unit={' ft3'}
                />

                <RenderComponent
                    title={'Energy'}
                    value={energy_consumption}
                    className="energy_vertical"
                    electrical_value={e_energy_consumption}
                    unit={' ' + userSpeed.toUpperCase() + '/h'}
                />

                <div className="set_temp_vertical">
                    <SetTemperature/>
                </div>

                <RenderButtons className="DeviceSec2 d-flex justify-content-start align-items-center align-self-end"/>

            </div>

        </div>
}

export default observer(Details);
