import React from "react";
import {observer} from "mobx-react";
import {authStore} from "../../../mobxStores/auth.store";
import {Button, Card, Typography} from "antd";
import classNames from "classnames";
import './styles.scss';

const Units = () => {

    const {userUnit, userSpeed, setUserUnit, setUserSpeed, updateUser} = authStore;

    const {Title} = Typography;

    const cardBodyStyle = {padding: '21px 22px 25px 20px'};

    const updateUserData = async (payload, type) => {
        try {

            if (type === 'unit') {
                setUserUnit(payload.temperature_unit);
            } else if (type === 'speed') {
                setUserSpeed(payload.speed_unit);
            }

            await updateUser(payload);

        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <div className="Units">

            <Title className="text-uppercase text-3f3f3f title">Units</Title>

            <Card
                className="Card"
                bordered={false}
                bodyStyle={cardBodyStyle}
            >

                <div className="CardContent">

                    <div className="Temperature">

                        <Title
                            level={5}
                            className="text-uppercase text-979797 title"
                        >
                            temperature
                        </Title>

                        <div className="btnsUnit d-flex justify-content-between align-items-center">

                            <Button
                                className=
                                    {
                                        classNames(
                                            [
                                                {
                                                    'btnUnit': true,
                                                    'bg-pri': userUnit === 'c',
                                                    'text-white': userUnit === 'c'
                                                }
                                            ]
                                        )
                                    }
                                onClick=
                                    {
                                        () => updateUserData({temperature_unit: 'c'}, 'unit')
                                    }
                            >
                                °C
                            </Button>

                            <Button
                                className=
                                    {
                                        classNames(
                                            [
                                                {
                                                    'btnUnit': true,
                                                    'bg-pri': userUnit === 'f',
                                                    'text-white': userUnit === 'f'
                                                }
                                            ]
                                        )
                                    }
                                onClick=
                                    {
                                        () => updateUserData({temperature_unit: 'f'}, 'unit')
                                    }
                            >
                                °F
                            </Button>

                        </div>

                    </div>

                    <div className="Speed">
                        <Title
                            level={5}
                            className="text-uppercase text-979797 title"
                        >
                            speed
                        </Title>

                        <div className="btnsUnit d-flex justify-content-between align-items-center">

                            <Button
                                className={classNames([
                                    {
                                        'btnUnit': true,
                                        'bg-pri': userSpeed == 'km',
                                        'text-white': userSpeed == 'km'
                                    }]
                                )
                                }
                                onClick=
                                    {
                                        () => updateUserData({speed_unit: 'km'}, 'speed')
                                    }
                            >
                                Km/h
                            </Button>

                            <Button
                                className=
                                    {
                                        classNames([
                                                {
                                                    'btnUnit': true,
                                                    'bg-pri': userSpeed == 'm',
                                                    'text-white': userSpeed == 'm'
                                                }
                                            ]
                                        )
                                    }
                                onClick=
                                    {
                                        () => updateUserData({speed_unit: 'm'}, 'speed')
                                    }
                            >
                                Miles/h
                            </Button>

                        </div>

                    </div>

                </div>

            </Card>

        </div>
    );
}

export default observer(Units);
