import React from 'react';

const Icon = ({name, ...rest}) => {
    const ImportedIconRef = React.useRef();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            try {
                ImportedIconRef.current = (await import(`@svgr/webpack?-svg!../../assets/svg/${name}.svg`)).ReactComponent;
                console.log('ImportedIconRef', ImportedIconRef);
            } catch (err) {
                // Your own error handling logic, throwing error for the sake of
                // simplicity
                throw err;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [name]);

    if (!loading && ImportedIconRef.current) {
        const {current: ImportedIcon} = ImportedIconRef;
        return <ImportedIcon {...rest} />;
    }

    return null;
};

export default Icon;
