import React from 'react';
import {observer} from "mobx-react";
import {Card, Typography} from "antd";
import {ReactComponent as Fire} from '../../assets/svg/Fire.svg';
import {useStore} from "../../mobxStores/stores";
import './styles.scss';

const Fuel = () => {

    const {zoneStore: {compoutedFuel}} = useStore();

    const {Title, Paragraph, Text} = Typography;

    return (
        <div className="Fuel">

            <Text className="text-6d6d6d title d-block text-uppercase">Fuel</Text>

            <Card
                className="Card border-0 d-flex justify-content-center align-items-center"
                bordered={false}
                bodyStyle={{padding: '9px 0px 10px 23px'}}
            >
                <Typography className="Body text-000 d-flex justify-content-center align-items-center">

                    <Fire/>

                    <div className="Content">

                        <Title className="title">{compoutedFuel}</Title>

                        <Paragraph className="paragraph text-979797">Total Fuel Consumption</Paragraph>

                    </div>

                </Typography>

            </Card>

        </div>
    );
}

export default observer(Fuel);
