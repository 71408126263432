import openSocket from 'socket.io-client';
import config from "../config";

const {URL} = config;

let socket;

export const initiateSocket = () => {
    socket = openSocket(URL);
    console.log(socket);
    console.log({URL})
    socket.on('connect_error', (e) => console.log('Connect Error', e))
    socket.on('connect', () => console.log('Connected'))
}

export const disconnectSocket = () => {
    console.log('Disconnecting socket...');
    if (socket) socket.disconnect();
}

export {socket}
