import React from "react";
import {Card, Typography} from "antd";
import {authStore} from "../../../mobxStores/auth.store";
import './styles.scss';

const PersonalInfo = () => {

    const {Text, Title} = Typography;

    const cardBodyStyle = {padding: '21px 23px 26px'};

    const {getNickName, userEmail, userRole, userName} = authStore;

    return (
        <div className="PersonalInfo">
            <Title
                className="text-uppercase text-3f3f3f title d-none d-lg-block"
            >
                personal information
            </Title>

            <Card
                className="Card"
                bordered={false}
                bodyStyle={cardBodyStyle}
            >

                <div className="CardContent">

                    <div className="icon">

                        <span>{getNickName}</span>

                    </div>

                    <div className="info">

                        <Text className="text-capitalize">

                            <span className="name text-3f3f3f">{userName}</span>

                            <span className="role text-979797 text-capitalize">{userRole}</span>

                        </Text>

                        <Text className="email text-979797">{userEmail}</Text>

                    </div>

                </div>

            </Card>

        </div>
    );
}

export default PersonalInfo;
