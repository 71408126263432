import React, {useEffect, useState} from "react";
import TableScheduledHeating from "./TableScheduledHeating";
import HeatingProgramDialog from "../HeatingProgramDialog";
import {Card, Select, Space, Typography, DatePicker, Button, Divider, message, Popconfirm} from "antd";
import {ReactComponent as CaretDown} from "../../assets/svg/CaretDown.svg";
import {ReactComponent as Plus} from "../../assets/svg/plus.svg";
import {DeleteTwoTone} from '@ant-design/icons'
import {useParams} from 'react-router-dom';
import {observer} from "mobx-react";
import {useStore} from "../../mobxStores/stores";
import './styles.scss';

import moment from "moment";
import {scheduleStore} from "../../mobxStores/schedules.store";

const ScheduledHeating = () => {

    const {
        zoneStore:
            {
                zone,
                options
            },
        deviceStore:
            {
                setDevices,
            },
        scheduleStore: {
            setFilter,
            clearFilter
        }
    } = useStore();

    const {id} = useParams();

    const {Title} = Typography;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(async () => {
        await setDevices(id);
        clearFilter()
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const deleteSelectedScheduled = () => {
        console.log(selectedRowKeys)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const headStyle = {
        padding: '0 25px'
    }
    const bodyStyle = {
        padding: '0'
    };

    const handleChange = (value, e) => {
        setFilter(e && e.children && e.children, 'device')
        console.log(`selected ${value}`);
    }

    const onChange = (value, dateString, type) => {
        if (type === 'start_date')
            setFilter(value && value.valueOf(), type)

        if (type === 'end_date')
            setFilter(value && value.valueOf(), type)
    }

    const confirm = async () => {

        await scheduleStore.deleteSelectedSchedule(selectedRowKeys)

        message.success('Selected Programs deleted successfully');

        setSelectedRowKeys([])

    }

    const cancel = (e) => {
    }

    const onFinish = async (values) => {

        try {

            console.log({values})

            await scheduleStore.addSchedule({
                device_ids: values.switch,
                start_date: moment(values.start_date).utc().valueOf(),
                end_date: moment(values.end_date).utc().valueOf(),
                set_temp: values.set_temp,
                zone_id: zone.zone_id,
                note: values.note
            })

            setIsModalVisible(false);

            message.success('scheduler added successfully')

        } catch (error) {

            setIsModalVisible(false);

            message.error(error.response.data.message);

        }

    };

    const onOk = (value, type) => {
        console.log('onOk: ', value, type);
    }

    return (
        <Card className="ScheduledHeating" bordered={false} headStyle={headStyle} bodyStyle={bodyStyle} title={

            <Title className="title text-1d1d1f text-left">

                <span>Scheduled Heating</span>

                <div className="float-right d-flex justify-content-center align-items-center d-lg-none">

                    <Button
                        onClick={showModal}
                        className="bg-none p-0 border-0 h-10"
                    >
                        <Plus/>
                    </Button>

                    {
                        selectedRowKeys.length > 0
                        && <Popconfirm
                            title="Are you sure to delete selected programs?"
                            onConfirm={() => confirm()}
                            onCancel={(e) => cancel(e)}
                            okText="Yes"
                            cancelText="Cancel"
                        >

                            <Button
                                title="Delete Selected Programs"
                                onClick={deleteSelectedScheduled}
                                className="bg-none p-0 border-0 h-10 ml-2"
                            >
                                <DeleteTwoTone/>

                            </Button>

                        </Popconfirm>
                    }
                </div>

            </Title>

        }>
            <Divider className="m-0 bg-divider"/>

            <div className="ScheduledBody d-flex justify-content-between align-items-center">

                <div className="ScheduledOption d-flex justify-content-between align-items-center">

                    <Select
                        className="SelectDevice text-center text-1d1d1f text-1d1d1f"
                        suffixIcon={<CaretDown/>}
                        bordered={false}
                        defaultValue=""
                        allowClear
                        onChange={handleChange}
                    >
                        <Select.Option value="" disabled>Select Device</Select.Option>

                        {
                            options && options.map((device, index) => (

                                <Select.Option key={index} value={device.value}>{device.label}</Select.Option>
                            ))
                        }

                    </Select>

                    <Space direction="vertical" size={12}>

                        <DatePicker
                            className="DatePicker"
                            showTime
                            bordered={false}
                            onChange={(val, opt) => onChange(val, opt, 'start_date')}
                            placeholder="Start Date/Time"
                            suffixIcon={<CaretDown/>}
                            onOk={(value) => onOk(value, 'start_date')}
                        />

                    </Space>

                    <Space direction="vertical" size={12}>

                        <DatePicker
                            className="DatePicker text-1d1d1f"
                            showTime
                            bordered={false}
                            // onChange={onChange}
                            onChange={(val, opt) => onChange(val, opt, 'end_date')}
                            placeholder="End Date/Time"
                            suffixIcon={<CaretDown/>}
                            onOk={(value) => onOk(value, 'end_date')}

                        />

                    </Space>
                </div>

                <div className="d-flex justify-content-center align-items-center">

                    <Button
                        className="btn-outline-primary BtnAddHeating d-none d-lg-block"
                        onClick={showModal}
                    >
                        New Heating Program
                    </Button>

                    {
                        selectedRowKeys.length > 0
                        && <Popconfirm
                            title="Are you sure to delete selected programs?"
                            onConfirm={() => confirm()}
                            onCancel={(e) => cancel(e)}
                            okText="Yes"
                            cancelText="Cancel"
                        >
                            <Button
                                className="btn-outline-danger d-none d-lg-block ml-2"
                                onClick={deleteSelectedScheduled}
                            >
                                Delete Selected Program
                            </Button>

                        </Popconfirm>
                    }

                </div>

            </div>

            <TableScheduledHeating setSelectedRowKeys={setSelectedRowKeys}/>

            <HeatingProgramDialog
                handleCancel={handleCancel}
                onFinish={onFinish}
                handleOk={handleOk}
                isModalVisible={isModalVisible}
            />

        </Card>
    );
}

export default observer(ScheduledHeating);
