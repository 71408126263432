import React from "react";
import {Layout, Menu} from 'antd';
import {Link} from "react-router-dom";
import {ReactComponent as SquaresFour} from "../../assets/svg/SquaresFour.svg";
import {ReactComponent as GearSix} from "../../assets/svg/GearSix.svg";
import './styles.scss';

const Footer = () => {
    return (
        <Layout.Footer className="Footer d-block d-lg-none p-0">

            <Menu
                theme="dark"
                style={{background: 'transparent'}}
                className="d-flex justify-content-center align-items-center h-100"
                mode="horizontal"
            >

                <Menu.Item
                    active={false}
                    unselectable="off"
                    key="1"
                    icon={<SquaresFour/>}
                >
                    <Link to='/dashboard'>Dashboard</Link>

                </Menu.Item>

                <Menu.Item
                    active={false}
                    unselectable="off"
                    key="2"
                    icon={<GearSix/>}
                >
                    <Link to='/settings'>Settings</Link>
                </Menu.Item>

            </Menu>

        </Layout.Footer>
    );
}

export default Footer;
