import React from "react";
import {Button} from "antd";
import './styles.scss';

const ButtonCustom = ({type, onClick, disabled}) => {
    return (
        <Button
            disabled={disabled}
            className="ButtonCustom"
            onClick={onClick}
        >
            {type}
        </Button>
    );
}
export default ButtonCustom;
