import React from "react";
import TableAuditHistory from "./TableAuditHistory";

const AuditTrailHistory = () => {
    return (
        <div className="AuditTrailHistory">
            <TableAuditHistory/>
        </div>
    )
}

export default AuditTrailHistory;
