import React from 'react';

import DateView from 'react-datepicker';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';
import {FormGroup} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
    const {label, name, hideLabel, ...rest} = props;
    return (
        <FormGroup style={rest.style}>
            {!hideLabel && <label htmlFor={name}>{label}</label>}
            <Field name={name}>
                {({form, field, meta}) => {
                    const {setFieldValue} = form;
                    const {value} = field;
                    console.log({rest, form});
                    return (
                        <div className="position-relative">
                            <DateView
                                id={name}
                                {...field}
                                {...rest}
                                selected={value}
                                className={[
                                    "form-control",
                                    meta.touched && meta.error
                                        ? "date-picker-validation-error"
                                        : " "
                                ].join(" ")}
                                onChange={val => setFieldValue(name, val)}
                            ></DateView>
                        </div>
                    );
                }}
            </Field>
            <ErrorMessage component={TextError} name={name}/>
        </FormGroup>
    );
}

export default DatePicker;
