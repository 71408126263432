import * as Yup from 'yup';

const addSchedule = Yup.object().shape({
    switch: Yup.array().of(Yup.object().shape({
        value: Yup.string().required('Select Device is required'),
        label: Yup.string().required('Select Device is required')
    })).min(1).required('Select Device is required'),
    set_temp: Yup.string().required('Set Temperature is required'),
    start_date: Yup.date().required('Start Date is required'),
    end_date: Yup.date().required('End Date is required'),
    note: Yup.string()
});

export {
    addSchedule as addScheduleValidationSchema
};

