import React from 'react';
import {withRouter} from 'react-router';
import {Layout} from 'antd';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import './index.scss';

const { Content } = Layout;
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            layoutConfig: {
                layoutType: "vertical",
                layoutWidth: "fluid",
                leftSideBarTheme: "dark",
                leftSideBarType: "condensed",
                topbarTheme: "light",
                isPreloader: false,
                showRightSidebar: false,
                isMobile: false,
                showSidebar: true,
                leftMenu: false,
            },
        };
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };
    componentDidMount() {
        const { layoutConfig } = this.state;

        // Scroll Top to 0
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

        document.title = currentage + " | UOS";
    }
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    render() {
        return (
            <Layout>
                <Layout className="site-layout" style={{ marginLeft: this.state.collapsed ? 80 : 200 }}>
                    <Header className={'d-none'} />
                    <Sidebar collapsed={this.state.collapsed} classes={['d-none', 'd-lg-block']} />
                    <Content className="site-layout-background bg-main">
                        {this.props.children}
                    </Content>
                    <Footer className={'d-none'} />
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(Dashboard);
