import * as React from "react"

function EnergyIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={37}
            height={39}
            fill="none"
            viewBox="0 0 37 39"
            {...props}
        >
            <g filter="url(#prefix__filter0_d)">
                <circle cx={18.5} cy={18.5} r={18.5} fill={props.fill}/>
                <circle cx={18.5} cy={18.5} r={18} stroke={props.fill}/>
            </g>
            <path
                fill={props.color}
                d="M24.268 17.854a.53.53 0 00-.327-.372l-3.786-1.42.963-4.818a.53.53 0 00-.275-.571.532.532 0 00-.625.11l-7.357 7.883a.517.517 0 00-.128.479.53.53 0 00.327.372l3.786 1.42-.963 4.817a.521.521 0 00.275.571.525.525 0 00.624-.11l7.358-7.883a.524.524 0 00.128-.478z"
            />
            <defs>
                <filter
                    id="prefix__filter0_d"
                    width={37}
                    height={39}
                    x={0}
                    y={0}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy={2}/>
                    <feColorMatrix values="0 0 0 0 0.302921 0 0 0 0 0.314445 0 0 0 0 0.374942 0 0 0 0.08 0"/>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default EnergyIcon
