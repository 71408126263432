import React from "react";
import { Login } from '../pages/Authentication';
import {Redirect} from 'react-router-dom';
import DeviceDetailsPage from "../pages/Dashboard/DeviceDetailsPage";
import LocationDetailsPage from "../pages/Dashboard/LocationDetailsPage";
import LocationsPage from "../pages/Dashboard/Locations";
import SettingsPage from "../pages/Dashboard/Settings";
const authRoutes = [
    { path: "/login",exact: true ,component: Login },
];

const homeRoutes = [
    { path: "/settings", component: SettingsPage},
    { path: "/dashboard/:platform/:id/details", component: DeviceDetailsPage},
    { path: `/dashboard/locations/:platform/:id`, component: LocationDetailsPage},
    { path: "/dashboard", exact: true, component: LocationsPage},
    { path: "/", exact: true,component: (props) =>  <Redirect  to={{ pathname: "/dashboard", state: { from: props.location } }} />},
    ];
export { authRoutes, homeRoutes };
