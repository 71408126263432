import {useEffect} from "react";
import {observer} from "mobx-react";
import {socket} from "../../context/socket";
import {useParams, Link} from "react-router-dom";
import {useStore} from "../../mobxStores/stores";
import {Breadcrumb, Col, Divider, PageHeader, Row} from "antd";
import MasterControls from "../MasterControls";
import OveralTemperature from "../OveralTemperature";
import WindFactor from "../WindTimer";
import Fuel from "../Fuel";
import Energy from "../Energy";
import Devices from "../Devices";
import ScheduledHeating from "../ScheduledHeating";
import WindFactorTriggers from "../WindFactorTriggers";
import Loader from "../Loader";
import './styles.scss';
import SelectPlatform from "../SelectPlatform";

const LocationDetails = () => {

    const {
        zoneStore:
            {
                setDevice,
                getCurrentZone,
                getZoneComputed,
                loading
            }
    } = useStore();

    const {id} = useParams();

    useEffect(async () => {

        await getCurrentZone({id: id})

    }, []);

    useEffect(() => {

        if (localStorage.getItem('platform') === 'switches') {
            socket.on('switchAudit', (data) => {
                setDevice(data)
                console.log('Switch Audited: ', data);
            });
        }else {
            socket.on('blowerAudit', (data) => {
                setDevice(data)
                console.log('Blower Audited: ', data);
            });
        }

    }, [])

    if (loading) return <Loader/>;

    const zone = getZoneComputed;

    return getZoneComputed && (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <Breadcrumb
                    separator=
                        {
                            <span className="text-a6a6a6">/</span>
                        }
                >

                    <Breadcrumb.Item className="text-capitalize text-a6a6a6">Locations</Breadcrumb.Item>

                    <Breadcrumb.Item className="text-capitalize text-a6a6a6">

                        <Link to="/dashboard" className="text-a6a6a6">{localStorage.getItem('platform')}</Link>

                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                        className="text-capitalize text-a6a6a6">
                        {
                            zone.zone_name ? zone.zone_name.toUpperCase() : null
                        }
                    </Breadcrumb.Item>

                </Breadcrumb>

                <SelectPlatform style={{minHeight: 35, height: 35}}/>

            </div>

            <Divider className="m-0 mt-2 bg-divider"/>

            <PageHeader
                className="p-0 text-3f3f3f details-header"
                title={<span className="text-title-zone"
                >
                    {
                        zone.zone_name
                    }
                </span>}
            />

            <Row>

                <Col>

                    <MasterControls/>

                </Col>

                <Col>

                    <OveralTemperature/>

                </Col>

                <Col className="LocationSetting">

                    <Row>

                        <Col className="windFactorCol">

                            <WindFactor/>

                        </Col>

                        <Col className="fuelCol">

                            <Fuel/>

                        </Col>

                        <Col className="energyCol">

                            <Energy/>

                        </Col>

                    </Row>

                </Col>

            </Row>

            <Divider className="bg-divider margin-divider"/>

            <Devices/>

            <ScheduledHeating/>

            <WindFactorTriggers/>
        </>
    );
}
export default observer(LocationDetails);
