import {action, computed, makeObservable, observable, toJS} from "mobx";
import {getAllZones, getCurrentZone, setOverallTemp, setSnowSensor, windTimer} from "../services/zones";
import {authStore} from "./auth.store";
import {convertCelsiusToFahrenheit, convertKmToMiles} from "../utils/helper";
import {updateDevice} from "../services/devices";
import moment from "moment";
import {message} from "antd";

export class ZoneStore {

    loading: boolean = false;
    loadingDevice: boolean = false;
    zones: any = [];
    zone: any = {};
    zone_devices: any;
    filter_zones: string = '';
    deadlineWind: number = 0;
    wind: number = 0;
    loadingWind: boolean = false;

    constructor() {
        makeObservable(this, {
            zones: observable,
            zone: observable,
            loading: observable,
            wind: observable,
            deadlineWind: observable,
            loadingDevice: observable,
            loadingWind: observable,
            filter_zones: observable,
            zone_devices: observable,
            getZones: action,
            getCurrentZone: action,
            updateDevice: action,
            masterSnowSensor: action,
            setOverallTemp: action,
            compoutedFuel: computed,
            compoutedEnergy: computed,
            getAllZones: computed,
            getDevicesComputed: computed,
            options: computed,
            windTimer: action
        });
    }

    getDeadlineWind() {
        return this.deadlineWind;
    }

    getWind() {
        return this.wind;
    }

    setWindActive = (val: number) => {
        this.wind = val;
    }
    setDeadlineWind = (deadline: number, hours: number) => {
        console.log(deadline)

        // const start_date = deadline;
        let end_date = deadline;

        // if (hours === 12) {
        //     end_date = moment(deadline).add(hours, "hours").valueOf();
        // }
        // if (hours === 24) {
        //     console.log('hourssssss', hours)
        //     end_date = moment(deadline).add(1, "days").valueOf();
        // }
        const currentTime = moment().valueOf();

        if (moment(currentTime).isBetween(moment().add(-hours, 'hour'), end_date)) {
            const duration = moment.duration(moment(end_date).diff(moment(currentTime)));
            const h = duration.asHours();
            this.deadlineWind = new Date().getTime() + h * 60 * 60 * 1000
        } else {
            this.deadlineWind = 0;
        }
    }

    get getAllZones() {
        let filtered_zones = [];
        if (this.filter_zones) {
            filtered_zones = this.zones.filter((zone: any) => {
                return zone.zone_name.toLowerCase().includes(this.filter_zones.toLowerCase());
            });
        } else {
            filtered_zones = [...this.zones];
        }
        if (authStore.userUnit === 'f') {
            filtered_zones = filtered_zones.map((item: any) => {
                console.log('itemssss', toJS(item))
                return {
                    ...item,
                    wind_temp_ext: convertCelsiusToFahrenheit(item.wind_temp_ext),
                    wind_temp_high: convertCelsiusToFahrenheit(item.wind_temp_high),
                    wind_temp_low: convertCelsiusToFahrenheit(item.wind_temp_low),
                    wind_temp_med: convertCelsiusToFahrenheit(item.wind_temp_med),
                    currently: {
                        ...item.currently,
                        temperature: convertCelsiusToFahrenheit(item.currently.temperature)
                    },
                    daily: {
                        ...item.daily,
                        data: item.daily.data.map((e: any) => ({
                            ...e,
                            temperatureMin: convertCelsiusToFahrenheit(e.temperatureMin),
                            temperatureMax: convertCelsiusToFahrenheit(e.temperatureMax),
                        }))
                    },
                }
            })
        }
        return filtered_zones;
    }

    setFilter = (filter: string) => {
        this.filter_zones = filter;
        console.log(this.filter_zones)
    }

    setZones = (zones: any) => {
        this.zones = zones;
    }

    getZones = async () => {
        try {
            this.loading = true;
            this.filter_zones = '';
            const res = await getAllZones();
            this.zones = res.data;
            console.log('zonesss', this.zones)
        } catch (error) {
            throw error;
        } finally {
            this.loading = false;
        }
    };

    masterSnowSensor = async (payload: object) => {
        try {
            await setSnowSensor(payload)
        } catch (e) {

        }
    }

    setOverallTemp = async (payload: any) => {
        try {
            await setOverallTemp(payload);
            message.success('Set Device/s Temperature To ' + payload.temp);

        } catch (e) {
            if (e.response.status === 400) {
                message.error(e.response.data.message)
            }
        }
    }

    windTimer = async (payload: any) => {
        try {
            this.loadingWind = true;
            const res = await windTimer(payload);
            this.setDeadlineWind(payload.end_date, payload.number_of_hours);
            if (payload.status === 1) {
                message.success('Wind Timer is on');
                this.setWindActive(payload.number_of_hours);
            }
            this.loadingWind = false;
            if (payload.status === 0) {
                this.setWindActive(0);
            }
        } catch (e) {
            if (e.response.status === 400) {
                message.error(e.response.data.message)
            }
        } finally {
            this.loadingWind = false;
        }
    }

    get compoutedFuel() {
        let sumFuels = 0;
        if (this.zone_devices) {
            this.zone_devices.map((item: any) => {
                console.log('itemsssss', item)
                if (item.gas_consumption) {
                    sumFuels += item.gas_consumption;
                }
                if (item.e_gas_consumption) {
                    sumFuels += item.e_gas_consumption;
                }
            })
        }
        return sumFuels;
    }

    get compoutedEnergy() {
        let sumEnergy = 0;
        if (this.zone_devices) {
            this.zone_devices.map((item: any) => {
                if (item.energy_consumption) {
                    sumEnergy += item.energy_consumption;
                }
                if (item.e_energy_consumption) {
                    sumEnergy += item.e_energy_consumption;
                }
            })
            if (authStore.userSpeed == 'm') {
                return convertKmToMiles(sumEnergy);
            }
        }
        return sumEnergy;
    }

    getCurrentZone = async (payload: any) => {
        try {
            this.loading = true;
            if (payload.id) {
                const res = await getCurrentZone(payload);
                this.zone = res.data;
                if (localStorage.getItem('platform') === 'switches') {
                    this.zone_devices = res.data.zone_switches;
                    console.log('switches', toJS(this.zone_devices))
                } else {
                    this.zone_devices = res.data.zone_devices;
                }
                if (this.zone_devices[0].wind === 1) {
                    console.log('wind is started')
                    console.log(this.zone_devices[0])
                    this.setWindActive(this.zone_devices[0].number_hours);
                    this.setDeadlineWind(this.zone_devices[0].wind_end, this.zone_devices[0].number_hours);
                } else {
                    this.setWindActive(0);
                    this.setDeadlineWind(0, 0)
                }
                console.log('this.zone', this.zone_devices);
            }
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

    setCurrentZone = async (zone: string) => {
        console.log('this.zones', this.zones)
        if (this.zones.length) {
            this.zone = this.zones.find((item: any) => {
                return item.zone_id === zone
            });
            console.log('this.zone', this.zone)
        } else {
            await this.getZones();
        }
    }

    get getZoneComputed() {
        if (this.zone) {
            let current_zone: any = {
                ...this.zone
            };
            if (authStore.userUnit == 'f') {
                current_zone = {
                    ...current_zone,
                    wind_temp_ext: convertCelsiusToFahrenheit(current_zone.wind_temp_ext),
                    wind_temp_high: convertCelsiusToFahrenheit(current_zone.wind_temp_high),
                    wind_temp_low: convertCelsiusToFahrenheit(current_zone.wind_temp_low),
                    wind_temp_med: convertCelsiusToFahrenheit(current_zone.wind_temp_med),
                }
                if (authStore.userSpeed == 'm') {
                    current_zone = {
                        ...current_zone,
                        max_wind_speed_low: convertKmToMiles(current_zone.max_wind_speed_low),
                        min_wind_speed_low: convertKmToMiles(current_zone.min_wind_speed_low),
                        max_wind_speed_med: convertKmToMiles(current_zone.max_wind_speed_med),
                        min_wind_speed_med: convertKmToMiles(current_zone.min_wind_speed_med),
                        max_wind_speed_high: convertKmToMiles(current_zone.max_wind_speed_high),
                        min_wind_speed_high: convertKmToMiles(current_zone.min_wind_speed_high),
                        max_wind_speed_ext: convertKmToMiles(current_zone.max_wind_speed_ext),
                        min_wind_speed_ext: convertKmToMiles(current_zone.min_wind_speed_ext),
                    }
                }
                return current_zone;
            }
        }
        return this.zone;
    }

    setDevice = (device: any) => {
        if (device) {
            console.log('device', device)
            console.log(toJS(this.zone_devices), 'this.zone_devices')
            const index = this.zone_devices.findIndex((item: any) => item.device_mac === device.device_mac);
            console.log({index})
            if (index !== -1) {
                this.zone_devices[index] = device;
            }
            this.setWindActive(device.number_hours || 0);
            this.setDeadlineWind(device.wind_end || 0, device.number_hours || 0);
        }
    }

    // setWindTimer = (val: number) => {
    //     if (val > 0) {
    //         this.zone_devices = this.zone_devices.map((item: any) => {
    //             return {
    //                 ...item,
    //                 wind: 1,
    //                 start_wind: moment(),
    //                 end_wind: moment().add(val, 'hours'),
    //             }
    //         })
    //     } else {
    //         this.zone_devices = this.zone_devices.map((item: any) => {
    //             return {
    //                 ...item,
    //                 wind: 0,
    //                 start_wind: null,
    //                 end_wind: null
    //             }
    //         })
    //     }
    //     console.log(toJS(this.zone_devices))
    // }

    get getDevicesComputed() {
        if (authStore.userUnit == 'f' || authStore.userSpeed == 'm') {
            if (this.zone_devices) {
                return this.zone_devices.map((item: any) => {
                    let set_temp,
                        e_set_temp,
                        enclosure_temp,
                        e_enclosure_temp,
                        energy_consumption,
                        e_energy_consumption;
                    let converted = {...item};
                    if (authStore.userUnit === 'f') {
                        set_temp = convertCelsiusToFahrenheit(item.set_temp);
                        e_set_temp = convertCelsiusToFahrenheit(item.e_set_temp);
                        enclosure_temp = convertCelsiusToFahrenheit(item.enclosure_temp);
                        e_enclosure_temp = convertCelsiusToFahrenheit(item.e_enclosure_temp);
                        converted = {
                            ...converted,
                            set_temp,
                            e_set_temp,
                            enclosure_temp,
                            e_enclosure_temp,
                        };
                    }
                    if (authStore.userSpeed === 'm') {
                        energy_consumption = convertKmToMiles(item.energy_consumption);
                        e_energy_consumption = convertKmToMiles(item.e_energy_consumption)
                        converted = {
                            ...converted,
                            energy_consumption,
                            e_energy_consumption
                        };
                    }
                    return converted
                });
            }
        }
        return this.zone_devices;
    }

    get options() {
        if (this.zone && this.zone_devices) {
            if (authStore.platform == 'blowers') {
                const all_dev_gas = this.zone_devices.map((item: any) => (
                    {
                        device_id: item.device_mac,
                        value: item.device_mac + 'gas',
                        label: item.device_name + ' Gas',
                        name: item.device_name,
                        type: 'gas'
                    }
                ));
                const all_dev_electrical = this.zone_devices.map((item: any) => (
                    {
                        value: item.device_mac + 'electrical',
                        label: item.device_name + ' Electrical',
                        name: item.device_name,
                        type: 'electrical'
                    }
                ))
                let all_dev = all_dev_gas.concat(all_dev_electrical);
                return all_dev;
            } else if (authStore.platform == 'switches') {
                const all_switches = this.zone_devices.map((item: any) => (
                    {
                        device_id: item.device_mac,
                        value: item.device_mac,
                        label: item.device_name + ' Switch',
                        name: item.device_name,
                        type: 'switch'
                    }
                ));
                return all_switches;
            }
        }
        return [];
    }

    updateDevice = async (payload: any) => {
        // this.loadingDevice = true;
        try {
            const res = await updateDevice(payload);
            // console.log('res', res.data.Attributes);
            // console.log({payload})
            // console.log('zone_devices', this.zone_devices)
            // let indexDevice = this.zone_devices.findIndex((item: any) => item.device_mac === payload.mac);
            // console.log('indexDevice', indexDevice)
            // let temp_arr = [...this.zone_devices];
            // temp_arr = [
            //     ...temp_arr.splice(0, indexDevice),
            //     Object.assign({}, temp_arr[indexDevice], res.data),
            //     ...temp_arr.splice(indexDevice + 1)
            // ];
            // this.loadingDevice = false;
            // this.zone_devices = [...temp_arr];

        } catch (e) {

        } finally {
            this.loadingDevice = false;
        }
    }
}

export const zoneStore = new ZoneStore();
