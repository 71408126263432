import axios from "axios";
import config from "../config";


const {BASE_URL} = config;

const apiClient = axios.create({
    baseURL: BASE_URL,
    responseType: "json"
})
apiClient.interceptors.request.use(async (request) => {
    try {
        const jwt = localStorage.getItem('token');
        if (jwt) {
            request.headers["Authorization"] = `Bearer ${jwt}`;
        }
        request.headers['Access-Control-Allow-Origin'] = '*'
    } finally {
        return request;
    }
}, error => {
    console.error(error);
})

// intercept response and handle global errors
apiClient.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, (error) => {

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error("error from inspector", error.response);
    return Promise.reject(error);
});

export const useApi = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return {
        get: apiClient.get,
        post: apiClient.post,
        put: apiClient.put,
        delete: apiClient.delete,
        patch: apiClient.patch,
        apiClient: apiClient,
        source: source,
    }
}
