import React from 'react';
import {dark, light} from '../themes';

function useTheme(isDark: boolean) {
    React.useLayoutEffect(() => {
        const theme = !!isDark ? dark : light;
        Object.entries(theme).forEach(([prop, value]) =>
            document.documentElement.style.setProperty(prop, value),
        );
    }, [isDark]);
}

export default useTheme;
