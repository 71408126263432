import * as React from "react"

const RssIcon = ({fill = '#3ccab0'}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25.792}
            height={19.056}
            viewBox="0 0 25.792 19.056"
        >
            <g stroke={fill} fill={fill} strokeLinecap="round" strokeLinejoin="round">
                <path
                    data-name="Path 1"
                    d="M1.98 5.921a15.439 15.439 0 0121.832 0M7.151 11.092a8.124 8.124 0 0111.49 0"
                    fill="none"
                    strokeWidth={2.8}
                />
                <path
                    data-name="Path 2"
                    d="M14.111 18.053a1.719 1.719 0 10-2.43 0 1.72 1.72 0 002.43 0z"
                    fill={fill}
                />
            </g>
        </svg>
    )
}

export default RssIcon
