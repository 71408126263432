import React from "react";
import {Select} from "antd";
import {authStore} from "../../mobxStores/auth.store";
import {useHistory} from 'react-router-dom';
import './styles.scss';
import {observer} from "mobx-react";

const SelectPlatform = ({style}) => {

    const history = useHistory();

    const handleChange = (value) => {
        console.log(`selected ${value}`);
       authStore.setPlatform(value)
        history.push('/');
    }

    return (
        <>
            <Select style={style} defaultValue={authStore.getPlatform()} className="SelectPlatform float-right text-1d1d1f"
                    onChange={handleChange}>
                <Select.Option value="blowers">Blowers</Select.Option>
                <Select.Option value="switches">Switches</Select.Option>
            </Select>
        </>
    )
}

export default observer(SelectPlatform)
