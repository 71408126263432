import {useEffect, useState} from "react";
import {Button, DatePicker, Select, Space} from "antd";
import {observer} from "mobx-react";
import {useStore} from "../../mobxStores/stores";
import classNames from 'classnames';
import {ReactComponent as CaretDown} from "../../assets/svg/CaretDown.svg";
import {Option} from "antd/es/mentions";
import TempIcon from "../TempIcon";
import EnergyIcon from "../EnergyIcon";
import moment from "moment";
import {deviceStore} from "../../mobxStores/devices.store";
import Loader from "../Loader";
import HighChart from "../CustomChart/HighChart";
import './styles.scss';

const GarphData = () => {

    const dateFormat = 'YYYY-MM-DD LTS';

    const [category, setCategory] = useState('temperature');

    const [deviceSelected, setDeviceSelected] = useState(deviceStore.device.device_mac);

    const [startDate, setStartDate] = useState(moment(new Date(), dateFormat, true));

    const [endDate, setEndDate] = useState(moment(new Date(), dateFormat, true).add(8, 'hours'));

    const handleChange = (value) => {
        setDeviceSelected(value)
        console.log(`selected ${value}`);
    }

    const styleEnergy = classNames({
        'text-979797': category !== 'energy',
        'text-20ace8': category === 'energy'
    })

    const styleTemp = classNames({
        'text-979797': category !== 'temperature',
        'text-20ace8': category === 'temperature'
    })

    const params = `category=${category}&device=${deviceSelected}&start_date=${startDate ? startDate.valueOf() : null}&end_date=${endDate ? endDate.valueOf() : null}`;

    const {
        zoneStore:
            {
                getDevicesComputed,
                getCurrentZone
            },
        deviceStore:
            {
                fetchReports,
                loadingReports,
                reports
            }
    } = useStore();

    useEffect(async () => {
        await getCurrentZone({id: deviceStore.device.zone_id})
        await fetchReports(params);
    }, [params])

    return (
        <div className="GarphData">

            <div className="GarphDataDiv d-flex justify-content-between align-items-center mb-31">

                <div className="d-flex justify-content-center align-items-center">

                    <div
                        className="cursor-pointer mr-1-5625"
                        onClick={() => {
                            setCategory('temperature');
                        }}
                    >

                        <Button className="p-0 border-0 bg-none mr-12px">
                            <TempIcon
                                color={category === 'temperature' ? '#fff' : '#CCC'}
                                fill={category === 'temperature' ? '#20ace8' : 'none'}
                            />
                        </Button>

                        <span className={styleTemp}>Temp</span>

                    </div>

                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            setCategory('energy');
                        }}
                    >
                        <Button className="p-0 border-0 bg-none mr-12px">
                            <EnergyIcon
                                color={category === 'energy' ? '#fff' : '#CCC'}
                                fill={category === 'energy' ? '#20ace8' : '#fff'}
                            />
                        </Button>

                        <span className={styleEnergy}>Energy</span>

                    </div>

                </div>

                <div className="ScheduledOption d-flex justify-content-between align-items-center">
                    <Select
                        className="SelectResponsive SelectDevice text-1d1d1f text-1d1d1f"
                        suffixIcon={<CaretDown/>}
                        bordered={false}
                        defaultValue={deviceSelected}
                        onChange={handleChange}
                    >
                        <Select.Option value="" disabled>Select Device</Select.Option>
                        {
                            getDevicesComputed && getDevicesComputed.map((device, index) => (
                                <Select.Option key={index} value={device.device_mac}>{device.device_name}</Select.Option>
                            ))
                        }
                    </Select>

                    <Space
                        className="SelectResponsive"
                        direction="vertical"
                        size={12}
                    >
                        <DatePicker
                            className="DatePicker"
                            defaultValue={startDate}
                            showTime
                            onChange={(e) => {
                                setStartDate(e)
                            }}
                            placeholder="Start Date/Time"
                            suffixIcon={<CaretDown/>}
                            onOk={(e) => {
                                setStartDate(e)
                            }}
                        />

                    </Space>

                    <Space
                        className="SelectResponsive"
                        direction="vertical"
                        size={12}
                    >

                        <DatePicker
                            className="DatePicker text-1d1d1f"
                            defaultValue={endDate}
                            showTime
                            onChange={(e) => {
                                setEndDate(e);
                            }}
                            placeholder="End Date/Time"
                            suffixIcon={<CaretDown/>}
                            onOk={(e) => {
                                setEndDate(e);
                            }}
                        />

                    </Space>

                </div>

            </div>

            {
                loadingReports
                    ? <Loader/>
                    : <HighChart category={category} reports={reports}/>
            }

        </div>
    );
}

export default observer(GarphData);
