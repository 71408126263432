import React from "react";
import Spinner from "reactstrap/lib/Spinner";
import "./styles.scss";

const CustomButton = ({
                          label,
                          loading,
                          disabled,
                          onClick,
                          className,
                          type,
                          padding,
                          ...rest
                      }) => {

    return (
        <button
            className={[`CustomButton border-0 text-uppercase text-white ${className}`]}
            disabled={disabled}
            type={type}
            onClick={onClick}
            {...rest}
        >
            {label}
            {loading && <Spinner className={"ml-1 align-middle"} size="sm"/>}
        </button>
    );
};

export default CustomButton;
