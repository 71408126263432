import {Tooltip} from "antd";
import {ReactComponent as Cancel} from "../../assets/svg/Cancel.svg";
import {ReactComponent as Warning} from "../../assets/svg/Warning.svg";
import React from "react";

const RenderFaults = ({isSsrFault, isFaultGrdElectrical, isFaultBMS, isFaultHPLP, placement= 'topRight'}) => {

    let renderFaultItem = [];
    if (isSsrFault && Array.isArray(isSsrFault) && isSsrFault.length) {
        let ssr_faults = 'SSR FAULT [';
        isSsrFault.map((item, key) => {
            if (item) {
                ssr_faults += 'HT' + (key + 1)
                if (key + 2 < isSsrFault.length) ssr_faults += ', '
            }
        })
        ssr_faults += ']';
        renderFaultItem.push({
            disabled: false,
            message: ssr_faults
        })
    }

    if (isFaultGrdElectrical) {
        renderFaultItem.push({
            disabled: true,
            message: 'Ground FAULT'
        })

    }

    if (isFaultBMS) {
        renderFaultItem.push({
            disabled: true,
            message: 'BMS FAULT'
        })
    }
    if (isFaultHPLP) {
        renderFaultItem.push({
            disabled: true,
            message: 'HP/LP FAULT'
        })
    }
    const ContainerFaults = <div>

        {renderFaultItem.length ?
            <Tooltip
                overlayClassName={'faultTooltip'}
                visible={true}
                className="float-right"
                style={{marginTop: 100}}
                placement={placement}
                title={
                    renderFaultItem.map((item, key) => (
                        <div className="text-12px mb-1" key={key}>
                            {item.disabled ? <Cancel width={19} height={19}/> : <Warning/>}
                            <span className="ml-2">{item.message}</span>
                        </div>
                    ))
                }/> : null
        }
    </div>

    return ContainerFaults;
}
export default RenderFaults
