import {action, makeObservable, observable} from 'mobx';
import {getUserData, login, updateUser} from '../services/auth';

export class AuthStore {
    loading: boolean = true;
    invitedUser: object | null = {};
    supportedLocations: any = [];
    unit: string = '';
    speed: string = '';
    dark: number = 0;
    layout: number = 0;

    // null = user data not requested yet
    // undefined = user is not validated
    // object = user is valid
    userData: any = null;
    platform: string = 'blowers';

    isSignedIn: boolean | undefined = undefined;

    constructor() {
        makeObservable(this, {
            loading: observable,
            unit: observable,
            speed: observable,
            platform: observable,
            dark: observable,
            layout: observable,
            isSignedIn: observable,
            invitedUser: observable,
            userData: observable,
            supportedLocations: observable,
            getUserData: action,
        });
    }

    setLoading = (value: boolean) => {
        this.loading = value;
    };

    getPlatform() {
        if (this.platform) {
            return this.platform;
        }
        if (localStorage.getItem('platform')) {
            return localStorage.getItem('platform');
        }
    }

    setPlatform = (plat: string | null | undefined) => {
        if (plat) {
            this.platform = plat;
            localStorage.setItem('platform', plat)
        } else {
            this.platform = 'blowers'
            localStorage.setItem('platform', 'blowers');
        }
    }
    setUserData = (user: Object | null | undefined) => {
        this.userData = user;
        if (this.userData && this.userData.type) {
            const type = this.userData.type;
            this.unit = this.userData.temperature_unit;
            this.speed = this.userData.speed_unit;
            this.dark = this.userData.dark_mode;
            this.layout = this.userData.vertical_layout;
        }
    };

    setInvData = (res: any) => {
        this.invitedUser = res.data;
    };

    get userId(): number {
        return this.userData.id;
    }

    get userName(): string {
        if (this.userData) {
            return `${this.userData.firstname} ${this.userData.lastname}`;
        } else {
            return '';
        }
    }

    get getNickName(): string {
        if (this.userData) {
            return `${this.userData.firstname[0]}${this.userData.lastname[0]}`;
        } else {
            return '';
        }
    }

    get userEmail(): string {
        if (this.userData) {
            return this.userData.email;
        } else {
            return '';
        }
    }


    get userInitials() {
        let initials = '';
        if (!this.userData) return initials;
        // @ts-ignore
        if (this.userData.firstName) initials += this.userData.firstName[0];
        // @ts-ignore
        if (this.userData.lastName) initials += this.userData.lastName[0];

        return initials.toUpperCase();
    }


    loginUser = async (userData: object) => {
        try {
            // this.loading = true;
            const response = await login({user: userData});
            const token = response.data.data.token;
            authStore.setUserData(response.data.data.user);
            authStore.setPlatform('blowers');
            authStore.setUserUnit(response.data.data.user.temperature_unit);
            authStore.setUserSpeed(response.data.data.user.speed_unit);
            authStore.setUserSpeed(response.data.data.user.speed_unit);
            authStore.setUserLayout(response.data.data.user.vertical_layout);
            authStore.setUserMode(response.data.data.user.dark_mode);
            localStorage.setItem('token', token);
            localStorage.setItem('platform', 'blowers');
        } catch (error) {
            throw error;
        } finally {
            // this.loading = false;
        }
    };

    updateUser = async (userData: object) => {
        try {
            return await updateUser({user: userData})
        } catch (error) {

        }
    }

    get userRole() {
        if (this.userData) {
            let role = this.userData.role.charAt(0).toUpperCase() + this.userData.role.slice(1);
            return role;
        }
        return '';
    }

    get userUnit() {
        if (this.unit) {
            return this.unit;
        }
        return 'c';
    }

    setUserUnit = (u: string) => {
        this.unit = u;
    }

    get userSpeed() {
        if (this.speed) {
            return this.speed;
        }
        return 'km';
    }

    setUserSpeed = (speed: string) => {
        this.speed = speed;
    }

    get userMode() {
        return this.dark;
    }

    setUserMode = (mode: number) => {
        this.dark = mode;
    }

    get userLayout() {
        return this.layout;
    }

    setUserLayout = (mode: number) => {
        this.layout = mode;
    }

    get displayName() {
        if (this.userData) {
            // @ts-ignore
            return [
                this.userData.firstName,
                this.userData.middleName,
                this.userData.lastName,
            ]
                .filter((item) => !!item)
                .map((word) => {
                    return word[0].toUpperCase() + word.substring(1);
                })
                .join(' ');
        }
        return '';
    }

    logout = async () => {
        console.log('logging out');
        this.setUserData(null);
        this.setPlatform('');
        localStorage.clear();
    };


    getUserData = async () => {
        try {
            console.log("enterded ")
            this.loading = true;
            // console.log("this.loading",this.loading)

            const res = await getUserData();
            if (res.data.data) {
                this.setUserData(res.data.data);
                this.setUserSpeed(res.data.data.speed_unit);
                this.setUserUnit(res.data.data.temperature_unit);
                this.setUserMode(res.data.data.dark_mode);
                this.setUserLayout(res.data.data.vertical_layout);
                if (localStorage.getItem('platform')) {
                    this.setPlatform(localStorage.getItem('platform'));
                }else {
                    this.setPlatform('blowers');
                }

                this.loading = false;

            }
        } catch (error) {
            // throw error;
        } finally {
            this.loading = false;
        }
    };
}

export const authStore = new AuthStore();
