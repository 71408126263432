import * as React from "react"

function FanIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={19}
            height={19}
            fill="none"
            viewBox="0 0 19 19"
        >
            <path
                fill={props.fill}
                d="M2.177 11.37a3.08 3.08 0 014.354-4.355L8.71 9.192l-4.355 4.355-2.177-2.178zm9.193-9.193a3.08 3.08 0 00-4.355 0L4.838 4.354 9.192 8.71l2.178-2.18a3.08 3.08 0 000-4.354zM7.015 16.208a3.08 3.08 0 004.355 0l2.177-2.177-4.355-4.355-2.177 2.177a3.08 3.08 0 000 4.355zm9.193-9.193a3.08 3.08 0 01-4.355 4.355L9.676 9.192l4.354-4.354 2.178 2.177z"
            />
            <circle cx={9.237} cy={9.237} r={2.211} fill={props.fill} stroke={props.stroke}/>
        </svg>
    )
}

export default FanIcon
