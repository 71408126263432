import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import {CloseOutlined} from '@ant-design/icons';
import {ReactComponent as SearchIcon} from "../../../assets/svg/MagnifyingGlass.svg";
import {ReactComponent as FunnelIcon} from "../../../assets/svg/Funnel.svg";
import {useForm} from "antd/es/form/Form";
import {observer} from "mobx-react";
import {useStore} from "../../../mobxStores/stores";
import SelectPlatform from "../../SelectPlatform";
import './styles.scss';

const Header = () => {

    const [showbtnSearch, setShowBtnSearch] = useState(false);

    const [form] = useForm();

    const {zoneStore: {setFilter}} = useStore();

    const handleChange = (e) => {
        console.log(e.target.value);
    }

    const onFinish = async (values) => {
        console.log('Success:', values);
        try {
            setFilter(values.search);
        } catch (e) {
            console.log(e);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="content-header">

            <h3 className="text-title-zone">Location Overview ({localStorage.getItem('platform')})</h3>

            <div className="d-flex justify-content-between align-items-center">
                <SelectPlatform/>

                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="d-none d-lg-flex justify-content-center align-items-center"
                >

                    <Form.Item className="header-form" name="search">

                        <Input
                            onChange={handleChange}
                            type="text"
                            className="header-input d-none d-lg-flex"
                            placeholder="Search"
                            prefix={<SearchIcon/>}
                        />

                    </Form.Item>

                    <Button htmlType="submit" className="header-btn d-none d-lg-block">
                        <FunnelIcon/>
                    </Button>

                </Form>

                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="d-flex d-lg-none justify-content-start align-items-center"
                >
                    <Form.Item
                        className="d-flex align-items-start justify-content-start m-0"
                        name="search"
                    >
                        <div className="position-relative">

                            <div
                                className="position-relative"
                                style={{zIndex: 1}}
                                onClick={() => setShowBtnSearch(!showbtnSearch)}
                            >
                                {
                                    showbtnSearch ? <CloseOutlined/> : <SearchIcon/>
                                }
                            </div>

                            {
                                showbtnSearch
                                    ? <Input
                                        name="search"
                                        type="text"
                                        className="header-input mobile position-absolute"
                                        placeholder="Search"
                                        prefix={<SearchIcon/>}
                                    />
                                    : null
                            }

                        </div>

                    </Form.Item>

                    <Button
                        htmlType="submit"
                        className="header-btn d-block d-lg-none bg-none border-0 w-auto h-auto ml-27"
                    >
                        <FunnelIcon/>
                    </Button>

                </Form>
            </div>


        </div>
    );
}

export default observer(Header);
