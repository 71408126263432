import React from 'react';
import {observer} from "mobx-react";
import {zoneStore} from "../../mobxStores/zones.store";
import {Button, Typography} from "antd";
import {ReactComponent as PowerOn} from '../../assets/svg/Frame 15.svg';
import {ReactComponent as PowerOff} from '../../assets/svg/Snowflake.svg';
import {useParams} from 'react-router-dom';
import './styles.scss';

const MasterControls = () => {

    const {id} = useParams();

    const {masterSnowSensor} = zoneStore;

    const snowSensorOn = () => {
        masterSnowSensor({
            zoneId: id,
            status: 1
        });
    }

    const snowSensorOff = () => {
        masterSnowSensor({
            zoneId: id,
            status: 0
        });
    }

    return (
        <div className="MasterControls">

            <Typography.Text className="text-6d6d6d title d-block text-uppercase">Master Controls</Typography.Text>

            <div className="d-flex btns-on-off">

                <Button className="btn-on bg-on" onClick={snowSensorOn}>

                    <div className="btn-icon">

                        <PowerOn/>

                    </div>

                    <span>ON</span>

                </Button>

                <Button className="btn-off bg-off text-a6a6a6" onClick={snowSensorOff}>

                    <div className="btn-icon">

                        <PowerOff/>

                    </div>

                    <span className="text-a6a6a6">OFF</span>

                </Button>

            </div>

        </div>
    );
}

export default observer(MasterControls);
