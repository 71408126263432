const {location: {hostname}} = window;

const fileName = hostname.includes('dev') ? 'dev' : 'qa'
let currentConfig;

try {
    currentConfig = require(`./${fileName}`);
} catch (error) {
    currentConfig = require('./dev');
}

export default currentConfig.default;
