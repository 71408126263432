import {computed, makeObservable, observable, action, toJS} from "mobx";
import {zoneStore} from "./zones.store";
import {authStore} from "./auth.store";
import {convertCelsiusToFahrenheit, convertKmToMiles} from "../utils/helper";
import {fetchAudits, fetchDevice, fetchDevices, fetchReports} from "../services/devices";
import moment from "moment";
import {message} from "antd";

export class DeviceStore {
    loading: boolean = false;
    loadingReports: boolean = false;
    loadingAudits: boolean = false;
    devices: any = [];
    reports: any = [];
    audits: any = [];
    device: any = {};

    constructor() {
        makeObservable(this, {
            devices: observable,
            device: observable,
            loading: observable,
            loadingReports: observable,
            loadingAudits: observable,
            reports: observable,
            audits: observable,
            getDevices: computed,
            computedDevice: computed,
            fetchDevices: action,
            fetchDevice: action,
            fetchReports: action,
        });
    }

    get getDevices() {
        if (authStore.userUnit == 'f' || authStore.userSpeed == 'm') {
            return this.devices.map((item: any) => {
                let set_temp,
                    e_set_temp,
                    enclosure_temp,
                    e_enclosure_temp,
                    energy_consumption,
                    e_energy_consumption;
                let converted = {...item};
                if (authStore.userUnit === 'f') {
                    set_temp = convertCelsiusToFahrenheit(item.set_temp);
                    e_set_temp = convertCelsiusToFahrenheit(item.e_set_temp);
                    enclosure_temp = convertCelsiusToFahrenheit(item.enclosure_temp);
                    e_enclosure_temp = convertCelsiusToFahrenheit(item.e_enclosure_temp);
                    converted = {
                        ...converted,
                        set_temp,
                        e_set_temp,
                        enclosure_temp,
                        e_enclosure_temp,
                    };
                }
                if (authStore.userSpeed === 'm') {
                    energy_consumption = convertKmToMiles(item.energy_consumption);
                    e_energy_consumption = convertKmToMiles(item.e_energy_consumption)
                    converted = {
                        ...converted,
                        energy_consumption,
                        e_energy_consumption
                    };
                }
                return converted
            });
        }
        return this.devices
    }

    getDevice = () => {
        return this.device;
    }

    get computedDevice() {
        console.log('devicessssss', toJS(this.device))

        if (authStore.userUnit == 'f' || authStore.userSpeed == 'm') {
            let set_temp,
                e_set_temp,
                enclosure_temp,
                e_enclosure_temp,
                energy_consumption,
                e_energy_consumption;
            let converted = {...this.device};
            if (authStore.userUnit === 'f') {
                set_temp = convertCelsiusToFahrenheit(this.device.set_temp);
                e_set_temp = convertCelsiusToFahrenheit(this.device.e_set_temp);
                enclosure_temp = convertCelsiusToFahrenheit(this.device.enclosure_temp);
                e_enclosure_temp = convertCelsiusToFahrenheit(this.device.e_enclosure_temp);
                converted = {
                    ...converted,
                    set_temp,
                    e_set_temp,
                    enclosure_temp,
                    e_enclosure_temp,
                };
            }
            if (authStore.userSpeed === 'm') {
                energy_consumption = convertKmToMiles(this.device.energy_consumption);
                e_energy_consumption = convertKmToMiles(this.device.e_energy_consumption)
                converted = {
                    ...converted,
                    energy_consumption,
                    e_energy_consumption
                };
            }
            return converted
        }
        console.log('devicessssss', toJS(this.device))
        return this.device;
    }

    setDevices = (zone: string) => {
        this.loading = true;
        // @ts-ignore
        try {
            const currentZone = zoneStore.zones.find((item: any) => {
                return item.zone_id === zone;
            });
            this.devices = currentZone.zone_devices;
            console.log({devices: this.devices})

        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

    fetchDevices = async (id: string) => {
        this.loading = true;
        this.devices = [];
        try {
            const res = await fetchDevices({id: id});
            console.log('deivces', res.data.Items);
            this.devices = res.data.Items;
        } catch (e) {
        } finally {
            this.loading = false;
        }
    }
    fetchDevice = async (id: string) => {
        this.loading = true;
        this.device = {};
        try {
            const res = await fetchDevice({id: id});
            console.log('deivcesss', res.data);
            this.device = res.data;
        } catch (e) {
            if (e.response.status === 404) {
                console.log(e.response)
                message.error(e.response.data.message)
            } else message.error(e.message)

        } finally {
            this.loading = false;
        }
    }

    fetchReports = async (params: any) => {
        this.loadingReports = true;
        this.reports = [];
        try {
            const res = await fetchReports(params);
            const keys = Object.values(res.data.data);
            const arr = keys.flat()
            let dataToDraw = null;
            if (keys.length === 3) {
                // dataToDraw = arr.reduce((acc: any, c: any) => {
                //     const indx = acc.findIndex((x: any) => x.time === c.created_at);
                //     if (c.value_type === "temperature_blower") {
                //         if (indx > -1) {
                //             acc[indx].enclosure = c.avg_value;
                //         } else {
                //             acc.push({ temperature: c.avg_value, time: c.created_at });
                //         }
                //     }
                //     if (c.value_type === "temperature_enclosure") {
                //         if (indx > -1) {
                //             acc[indx].enclosure = c.avg_value;
                //             console.log({ acc });
                //         } else {
                //             acc.push({ enclosure: c.avg_value, time: c.created_at });
                //         }
                //     }
                //     if (c.value_type === "temperature_weather") {
                //         if (indx > -1) {
                //             acc[indx].weather = c.avg_value;
                //         } else {
                //             acc.push({ weather: c.avg_value, time: c.created_at });
                //         }
                //     }
                //
                //     return acc;
                // }, []);

                let reduceArr = keys.map((item: any) => {
                    if (authStore.userUnit === 'f') {
                        return item.map((obj: any) => ({
                            x: obj.created_at,
                            y: convertCelsiusToFahrenheit(obj.avg_value)
                        }))
                    }
                    return item.map((obj: any) => ({
                        x: obj.created_at,
                        y: obj.avg_value
                    }))

                })
                console.log({reduceArr})
                this.reports = [
                    {
                        name: "Temperature",
                        tooltip: {
                            valueSuffix: ' °' + authStore.userUnit.toUpperCase()
                        },
                        data: reduceArr[0]
                    }, {
                        name: "Enclosure",
                        tooltip: {
                            valueSuffix: ' °' + authStore.userUnit.toUpperCase()
                        },
                        data: reduceArr[1]
                    }, {
                        name: "Weather",
                        tooltip: {
                            valueSuffix: ' °' + authStore.userUnit.toUpperCase()
                        },
                        data: reduceArr[2]
                    }
                ];
            } else if (keys.length === 1) {
                // console.log({keys})
                // dataToDraw = arr.map((item: any) => ({
                //     time: item.created_at,
                //     energy: item.avg_value
                // }))

                dataToDraw = arr.map((item: any) => ({
                    x: item.created_at,
                    y: item.avg_value
                }))
                console.log({dataToDraw})
                if (dataToDraw) {
                    this.reports = [{
                        name: 'Energy',
                        tooltip: {
                            valueSuffix: ' ft3'
                        },
                        data: dataToDraw
                    }];
                } else {
                    this.reports = [{
                        name: 'Energy',
                        data: [{
                            x: 0, y: 0
                        }]
                    }];
                }
            }
            // if (keys.length === 0) {
            //     this.reports = [
            //         {
            //             name: 'Energy',
            //             data: [{x:0,y:0}]
            //         }
            //
            //     ]
            // }
            this.loadingReports = false
            console.log('reports', toJS(this.reports))
        } catch (e) {
        } finally {
            this.loadingReports = false;
        }
    }

    fetchAudits = async (payload: any) => {
        this.loadingAudits = true;
        try {
            const res = await fetchAudits(payload)
            console.log({res})
            this.audits = res.data.data;
            this.loadingAudits = false;
        } catch (e) {


        } finally {
            this.loadingAudits = false;
        }
    }

    setDevice = (device: any) => {
        if (device) {
            if (device.device_mac === this.device.device_mac) {
                this.device = device
            }
        }
    }
}

export const deviceStore = new DeviceStore();
