import React from 'react';
import Input from './Input';
import Checkbox from './CheckBox';
import DatePicker from './DatePicker';
import MultiSelect from './MultiSelect';
import Select from './Select';
import './index.scss';

function FormikControl(props) {
    const {control, ...rest} = props;
    switch (control) {
        case 'input':
            return <Input {...rest} />;
        case 'select':
            return <Select {...rest} />;
        case 'multiselect':
            return <MultiSelect {...rest} />;
        case 'checkbox':
            return <Checkbox {...rest} />;
        case 'date':
            return <DatePicker {...rest} />;
        default:
            return null;
    }
}

export default FormikControl;
