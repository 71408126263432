import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../mobxStores/stores";

import {Card, Col, Typography, Tabs, Tooltip} from "antd";

import {ReactComponent as HotAirIcon} from "../../../assets/svg/Frame 11.svg";
import {ReactComponent as ElectricalIcon} from "../../../assets/svg/Frame 10.svg";
import {ReactComponent as Warning} from "../../../assets/svg/Warning.svg";
import {ReactComponent as Cancel} from "../../../assets/svg/Cancel.svg";
import Details from "./Details";
import {useMediaQuery} from "../../../utils/helper";
import classNames from "classnames";
import {authStore} from "../../../mobxStores/auth.store";
import './styles.scss';
import RenderFaults from "../../Faults";

const Device = ({device}) => {
    const {TabPane} = Tabs;

    const {Title} = Typography;

    const {authStore: {userLayout}, zoneStore: {updateDevice}} = useStore();

    const isFaultGrd = device.grd === 1;

    const isFaultGrdElectrical = device.e_grd === 1;

    // const isSsrFault = [1,0,1,0,1];

    const isSsrFault = device.e_fault;

    const isFaultBMS = device.bms_err === 1

    const isFaultHPLP = device.hplp_err === 1;

    const [tab, setTab] = useState(0);

    const [width] = useMediaQuery();

    const callback = (key) => {
        console.log(key);
        setTab(key)
    }

    const updateDeviceFun = async (device, payload) => {
        console.log('update Device', device, payload);
        await updateDevice(payload)
    }

    const platforms = {
        blowers: [
            {
                name: <span className="ml-13">Hot Air Blower</span>,
                type: 'blower',
                icon: <HotAirIcon fill={tab == 0 ? '#20ace8' : '#CCC'}/>
            },
            {
                name: <span className="ml-13">Electrical</span>,
                type: 'electrical',
                icon: <ElectricalIcon fill={tab == 1 ? '#20ace8' : '#CCC'}/>

            }
        ],
        switches: [
            {
                name: <span className="ml-13">Switches</span>,
                type: 'switch',
                icon: <HotAirIcon fill={tab == 0 ? '#20ace8' : '#CCC'}/>
            }
        ]
    }


    return (

        <Col
            className={
                classNames({
                    'Device': true,
                    // 'DeviceFault': isFaultGrd,
                    'Vertical': width >= 1270 && userLayout === 1
                })}
            span={width >= 1270 && userLayout === 1 ? 24 : 8}
        >

            <Title className="title text-title-zone text-uppercase">
                {device.device_name}
            </Title>

            <Card
                className="CardDevice"
                bordered={false}
                bodyStyle={{padding: 0}}
            >

                <RenderFaults isFaultBMS={isFaultBMS} isFaultGrdElectrical={isFaultGrdElectrical}
                              isFaultHPLP={isFaultHPLP} isSsrFault={isSsrFault}/>

                <Tabs
                    tabBarStyle={{padding: 0, width: '100%'}}
                    className="Tabs text-979797"
                    onChange={callback}
                >
                    {
                        authStore.getPlatform() === 'blowers' &&
                        platforms.blowers.map((item, key) =>
                            <TabPane
                                className="TabPane"
                                // disabled={isFaultGrd || isFaultHPLP}
                                tab={
                                    <div
                                        style={{minHeight: 17, padding: '0px 0px 0px 41px', marginRight: 0}}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        {item.icon}
                                        {item.name}
                                    </div>
                                }
                                key={key}
                            >

                                <Details
                                    isFaultGrd={isFaultGrdElectrical}
                                    device={device}
                                    type={item.type}
                                    isFaultHPLP={isFaultHPLP}
                                    isFaultBMS={isFaultBMS}
                                    updateDevice={updateDeviceFun}
                                />

                            </TabPane>
                        )
                    }
                    {
                        authStore.getPlatform() === 'switches' &&
                        platforms.switches.map((item, key) =>
                            <TabPane
                                className="TabPane"
                                disabled={isFaultGrd}
                                tab={
                                    <div
                                        style={{minHeight: 17, padding: '0px 0px 0px 41px', marginRight: 0}}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        {item.icon}
                                        {item.name}
                                    </div>
                                }
                                key={key}
                            >

                                <Details
                                    isFaultGrd={isFaultGrd}
                                    isFaultHPLP={isFaultHPLP}
                                    device={device}
                                    type={item.type}
                                    updateDevice={updateDeviceFun}
                                />

                            </TabPane>
                        )
                    }

                </Tabs>

            </Card>

        </Col>
    )
}

export default observer(Device);
