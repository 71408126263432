import React, {useState} from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';
import FormGroup from 'reactstrap/lib/FormGroup';
import ReactSelect, {components} from 'react-select';

const CaretDownIcon = () => {
    return <></>;
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>
    );
};

const styles = {
    singleValue: (styles) => {
        return {
            ...styles,
            textTransform: 'capitalize',
            fontSize: 14,
            fontWeight: 500,
            color: '#000000',
        };
    },
    option: (styles, state) => {
        return {
            ...styles,
            textTransform: 'capitalize',
        };
    },
};

function Select(props) {
    const {
        label,
        defaultLabel,
        required,
        style,
        name,
        options,
        onSelect,
        isLoading,
        ...rest
    } = props;

    const custom_options = options.map(item => ({
        value: item.device_mac,
        label: item.device_name
    }))
    return (
        <FormGroup style={style}>
            {label && (
                <label className={required && 'required'} htmlFor={name}>
                    {label}
                </label>
            )}
            <Field name={name}>
                {({field, form}) => {
                    return (
                        <ReactSelect
                            name={name}
                            components={{DropdownIndicator}}
                            options={custom_options}
                            value={custom_options?.find((option) => option.value === field.value)}
                            className='monmedx-select'
                            classNamePrefix='monmedx-select'
                            placeholder={defaultLabel}
                            onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                            }}
                            instanceId={props.iid || Date.now()}
                            styles={styles}
                            isLoading={isLoading}
                            {...rest}
                        />
                    );
                }}
            </Field>

            <ErrorMessage component={TextError} name={name}/>
        </FormGroup>
    );
}

export default Select;
