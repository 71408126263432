import {Card, Typography} from "antd";
import _ from 'lodash';
import Trigger from "./Trigger";
import {observer} from "mobx-react";
import {zoneStore} from "../../mobxStores/zones.store";
import './styles.scss';

const WindFactorTriggers = () => {

    const bodyStyle = {
        padding: 0
    };

    const zone = zoneStore.getZoneComputed;

    const triggers = [
        {
            name: 'Low wind factor',
            miles: zone.max_wind_speed_low,
            hour: zone.min_wind_speed_low,
            temp: zone.wind_temp_low,
        },
        {
            name: 'Med wind factor',
            miles: zone.max_wind_speed_med,
            hour: zone.min_wind_speed_med,
            temp: zone.wind_temp_med,
        },
        {
            name: 'High wind factor',
            miles: zone.max_wind_speed_high,
            hour: zone.min_wind_speed_high,
            temp: zone.wind_temp_high,
        },
        {
            name: 'Extrm wind factor',
            miles: zone.max_wind_speed_ext,
            hour: zone.min_wind_speed_ext,
            temp: zone.wind_temp_ext,
        }
    ];

    return (
        <div className="WindFactorTriggers">

            <Typography.Title className="title text-6d6d6d text-uppercase">wind factor triggers</Typography.Title>

            <Card className="Card" bodyStyle={bodyStyle} bordered={false}>

                <div className="d-flex justify-content-start align-items-center -trigger">
                    {
                        _.map(triggers, (item, index) => (
                            <Trigger key={index} item={item}/>
                        ))
                    }
                </div>

            </Card>

        </div>
    );
}

export default observer(WindFactorTriggers);
