import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link, useParams} from "react-router-dom";
import {Breadcrumb, Button, Divider, message, Typography} from "antd";
import {ReactComponent as PowerOn} from "../../assets/svg/device-on.svg";
import {ReactComponent as PowerOff} from "../../assets/svg/device-off.svg";
import {ReactComponent as Snowflake} from "../../assets/svg/Snowflake2.svg";
import DataLogging from "../DataLogging";
import SetTemp from "../SetTemp";
import {useStore} from "../../mobxStores/stores";
import Loader from "../Loader";
import {deviceStore} from "../../mobxStores/devices.store";
import {socket} from "../../context/socket";
import classNames from "classnames";
import FanIcon from "../FanIcon";
import {authStore} from "../../mobxStores/auth.store";
import SelectPlatform from "../SelectPlatform/SelectPlatform";
import {ReactComponent as HotAirIcon} from "../../assets/svg/Frame 11.svg";
import {ReactComponent as ElectricalIcon} from "../../assets/svg/Frame 10.svg";
import './styles.scss';

const DeviceDetails = () => {

    const {Title, Text} = Typography;

    const {
        authStore:
            {
                userUnit,
                userSpeed
            },
        deviceStore:
            {
                computedDevice,
                fetchDevice,
                loading
            },
        zoneStore:
            {
                updateDevice,
            }
    } = useStore();

    const {
        device_mac,
        set_temp,
        e_set_temp,
        device_active,
        e_switch_status,
        zone_city,
        device_name,
        zone_id,
        enclosure_temp,
        e_enclosure_temp,
        energy_consumption,
        e_energy_consumption,
        gas_consumption,
        e_gas_consumption,
        grd,
        e_grd,
        snow_sensor,
        snow_detection,
        e_snow_sensor,
        e_snow_detection,
        schedule,
        e_schedule,
        wind,
        heater_temperature,
        e_heater_temperature,
        e_fault,
        bms_err,
        hplp_err,
        deviceType
    } = computedDevice;
    console.log(computedDevice.deviceType)

    const isGrdFault = e_grd === 1;

    // const isSsrFault = [1,0,1,0,1];

    const isSsrFault = e_fault;

    const isFaultBMS = bms_err === 1

    const isFaultHPLP = hplp_err === 1;

    const isSnowEnable = snow_sensor === 1;

    const isWindOn = wind === 1;

    const isSchedOn = schedule === 1;

    const isElectricalSchedOn = e_schedule === 1;

    const isSnowDetection = snow_detection === 1;

    const isSnowEnableElectrical = e_snow_sensor === 1;

    const isSnowDetectionElectrical = e_snow_detection === 1;

    const isActiveBlower = device_active === 1

    const isActiveEelectrical = e_switch_status === 1

    const snowSensorOnOff = (type) => {

        if (isSchedOn && type === 'blower') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isElectricalSchedOn && type === 'electrical') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isWindOn) {
            message.warning('Wind Timer is active')
            return;
        }
        if (type) {
            const payload = type === 'blower'
                ? ({
                    mac: device_mac,
                    snow_sensor: isSnowEnable ? 0 : 1,
                    snow_detection: isSnowEnable ? 0 : 1,
                    device_active: isActiveBlower ? 1 : isSnowEnable ? 0 : 1
                })
                : ({
                    mac: device_mac,
                    e_snow_sensor: isSnowEnableElectrical ? 0 : 1,
                    e_snow_detection: isSnowEnableElectrical ? 0 : 1,
                    e_switch_status: isActiveEelectrical ? 1 : isSnowEnableElectrical ? 0 : 1
                })

            updateDevice(payload);
        }
    }
    const deviceOnOff = (type) => {

        if (isFaultHPLP && type === 'blower') {
            message.warning('HP/LP Fault!')
            return;
        }

        if (isFaultBMS && type === 'blower') {
            message.warning('BMS Fault!')
            return;
        }

        if (isGrdFault && type === 'electrical') {
            message.warning('Ground Fault!')
            return;
        }

        console.log({isSchedOn})
        if (isSchedOn && type === 'blower') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isElectricalSchedOn && type === 'electrical') {
            message.warning('Schedule Heating is active')
            return;
        }

        if (isWindOn) {
            message.warning('Wind Timer is active')
            return;
        }
        if (type) {
            if (type === 'blower') {
                if (isSnowEnable) {
                    message.warning('Snow Sensor run');
                    return;
                }
            }

            if (type === 'electrical') {
                if (isSnowEnableElectrical) {
                    message.warning('Snow Sensor run');
                    return;
                }
            }

            const payload = type === 'blower'
                ? ({
                    mac: device_mac,
                    device_active: isActiveBlower ? 0 : 1
                })
                : ({
                    mac: device_mac,
                    e_switch_status: isActiveEelectrical ? 0 : 1
                })

            updateDevice(payload);
        }
    }

    const {id} = useParams();

    useEffect(async () => {
            await fetchDevice(id);
        },
        []
    );

    useEffect(() => {

        if (localStorage.getItem('platform') === 'switches') {
            socket.on('switchAudit', (data) => {
                deviceStore.setDevice(data)
                console.log('Switch Audited: ', data);
            });
        } else {
            socket.on('blowerAudit', (data) => {
                deviceStore.setDevice(data)
                console.log('Blower Audited: ', data);
            });
        }
    }, []);

    const handleFan = async () => {
        let payload = {
            mac: device_mac,
            fan_status: computedDevice.fan_status === 0 ? 1 : 0
        }
        await updateDevice(payload)
        if (computedDevice.fan_status === 0) message.success('Fan is Enabled');
        else message.success('Fan is Disabled');

    }

    if (loading) return <Loader/>;

    if (!Object.keys(computedDevice).length) return null

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">

                <Breadcrumb separator={<span className="text-a6a6a6">/</span>}>

                    <Breadcrumb.Item className="text-capitalize">
                        <Link to='/dashboard' className="text-a6a6a6">Locations</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item className="text-capitalize">
                        <Link to="/dashboard" className="text-a6a6a6">blowers</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item className="text-capitalize">
                        <Link to={'/dashboard/locations/' + authStore.getPlatform() + '/' + zone_id}
                              className="text-a6a6a6">Lachine 1</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item className="text-capitalize text-a6a6a6">Details</Breadcrumb.Item>

                </Breadcrumb>

                <SelectPlatform style={{minHeight: 35, height: 35}}/>

            </div>

            <Divider className="mr-12px mb-17"/>

            <Typography.Title level={5}>{zone_city}</Typography.Title>

            <Typography.Text>{device_name}</Typography.Text>

            <div className="HeaderDetails d-flex justify-content-between align-items-end">
                <div className="Details1 d-flex justify-content-start align-items-end">
                    <div className="mr-1-75 d-flex justify-content-center align-items-center">

                        <span className="mr-2"><HotAirIcon fill={isActiveBlower ? '#20ace8' : '#CCC'} width={30}
                                                           height={30}/></span>
                        <Button
                            className="p-0 border-0 bg-none h-34"
                            disabled={isFaultBMS || isFaultHPLP}
                            onClick={() => deviceOnOff('blower')}
                        >
                            {
                                isActiveBlower ? <PowerOn/> : <PowerOff/>
                            }
                        </Button>

                    </div>

                    <div className="DivDetail mr-2-75">
                        <Title
                            level={5}
                            className="title text-979797"
                        >
                            Current {deviceType}
                        </Title>

                        <Text
                            className={
                                classNames({
                                    'value': true,
                                    'text-uppercase': true,
                                    'text-000': isActiveBlower && !isFaultHPLP && !isFaultBMS,
                                })
                            }
                        >
                            {
                                isActiveBlower && !isFaultHPLP && !isFaultBMS ? heater_temperature + '°' + userUnit.toUpperCase() : '_'
                            }
                        </Text>

                    </div>

                    <div className="DivDetail mr-2-625">
                        <Title
                            level={5}
                            className="title text-979797"
                        >
                            Enclosure temp
                        </Title>

                        <Text className="value text-uppercase">
                            {
                                isActiveBlower && !isFaultHPLP && !isFaultBMS && enclosure_temp ? enclosure_temp + '°' + userUnit.toUpperCase() : '_'
                            }
                        </Text>

                    </div>

                    <div className="DivDetail mr-2-5625">

                        <Title
                            level={5}
                            className="title text-979797"
                        >
                            Fuel
                        </Title>

                        <Text
                            className="value">
                            {
                                isActiveBlower && !isFaultHPLP && !isFaultBMS && gas_consumption ? gas_consumption + ' ft3' : '_'
                            }
                        </Text>

                    </div>
                    <div className="DivDetail">

                        <Title
                            level={5}
                            className="title text-979797"
                        >
                            Energy
                        </Title>

                        <Text className="value">
                            {
                                isActiveBlower && !isFaultHPLP && !isFaultBMS && energy_consumption ? energy_consumption + userSpeed.toUpperCase() + '/h' : '_'
                            }
                        </Text>

                    </div>

                </div>
                <div className="Details2 d-flex justify-content-center  align-items-end">
                    <div className="DivDetail">
                        <Title
                            level={5}
                            className="title text-979797"
                        >
                            set temperature
                        </Title>

                        <SetTemp
                            currentTemp={set_temp}
                            newTemp={set_temp}
                            isFault={isFaultBMS || isFaultHPLP}
                            isActive={isActiveBlower}
                            type="blower"
                            mac={device_mac}
                            isSnowEnable={isSnowEnable}
                            isWindOn={isWindOn}
                            isSchedOn={isSchedOn}
                        />

                    </div>
                    <div className="DivBtns d-flex justify-content-center align-items-center">
                        <Button
                            onClick={() => snowSensorOnOff('blower')}
                            className={classNames({
                                'BtnSnowSensor': true,
                                'BtnFan': isSnowEnable && !isWindOn && !isSchedOn
                            })}
                            disabled={isFaultBMS || isFaultHPLP || isWindOn || isSchedOn}
                        >
                            <Snowflake fill={isSnowEnable || isSnowDetection ? '#fff' : '#9a9a9a'}/>

                            <span>Snow Sensor</span>

                        </Button>

                        {
                            deviceType === 'blowers' ? <Button
                                onClick={handleFan}
                                className={classNames({
                                    'BtnSnowSensor': true,
                                    'BtnFanWidth': true,
                                    'BtnFan': computedDevice.fan_status === 1,
                                })}
                            >
                                <FanIcon
                                    fill={computedDevice.fan_status === 1 ? '#fff' : '#9a9a9a'}
                                    stroke={computedDevice.fan_status === 1 ? '#208EE8' : '#fff'}
                                />

                                <span>Fan Only</span>

                            </Button> : null
                        }
                    </div>
                </div>
            </div>
            {
                deviceType === 'blowers' ?
                    <div className="HeaderDetails d-flex justify-content-between align-items-end">

                        <div className="Details1 d-flex justify-content-start align-items-end">

                            <div className="mr-1-75">

                        <span className="mr-2">
                            <ElectricalIcon fill={isActiveEelectrical ? '#20ace8' : '#CCC'}
                                            width={30} height={30}/></span>

                                <Button
                                    className="p-0 border-0 bg-none h-34"
                                    disabled={isGrdFault}
                                    onClick={() => deviceOnOff('electrical')}
                                >
                                    {
                                        isActiveEelectrical ? <PowerOn/> : <PowerOff/>
                                    }
                                </Button>

                            </div>

                            <div className="DivDetail mr-2-75">
                                <Title
                                    level={5}
                                    className="title text-979797"
                                >
                                    Current
                                </Title>

                                <Text
                                    className={
                                        classNames({
                                            'value': true,
                                            'text-uppercase': true,
                                            'text-000': isActiveEelectrical && !isGrdFault,
                                        })
                                    }
                                >
                                    {
                                        isActiveEelectrical && !isGrdFault ? e_heater_temperature + '°' + userUnit.toUpperCase() : '_'
                                    }
                                </Text>

                            </div>

                            <div className="DivDetail mr-2-625">

                                <Title
                                    level={5}
                                    className="title text-979797"
                                >
                                    Enclosure temp
                                </Title>

                                <Text
                                    className="value text-uppercase"
                                >
                                    {
                                        isActiveEelectrical && !isGrdFault && e_enclosure_temp ? e_enclosure_temp + '°' + userUnit.toUpperCase() : '_'
                                    }
                                </Text>

                            </div>

                            <div className="DivDetail mr-2-5625">

                                <Title
                                    level={5}
                                    className="title text-979797"
                                >
                                    Fuel
                                </Title>

                                <Text
                                    className="value"
                                >
                                    {
                                        isActiveEelectrical && !isGrdFault && e_gas_consumption ? e_gas_consumption + ' ft3' : '_'
                                    }
                                </Text>

                            </div>

                            <div className="DivDetail">

                                <Title
                                    level={5}
                                    className="title text-979797"
                                >
                                    Energy
                                </Title>

                                <Text className="value">
                                    {
                                        isActiveEelectrical && !isGrdFault && e_energy_consumption ? e_energy_consumption + userSpeed.toUpperCase() + '/h' : '_'
                                    }
                                </Text>

                            </div>

                        </div>
                        <div className="Details2 d-flex justify-content-center  align-items-end">

                            <div className="DivDetail">

                                <Title
                                    level={5}
                                    className="title text-979797"
                                >
                                    set temperature
                                </Title>

                                <SetTemp
                                    currentTemp={e_set_temp}
                                    newTemp={e_set_temp}
                                    isFault={isGrdFault}
                                    isActive={isActiveEelectrical}
                                    type="electrical"
                                    mac={device_mac}
                                    isSnowEnable={isSnowEnableElectrical}
                                    isWindOn={isWindOn}
                                    isSchedOn={isElectricalSchedOn}
                                />

                            </div>

                            <div className="DivBtns d-flex justify-content-center align-items-center">
                                <Button
                                    onClick={() => snowSensorOnOff('electrical')}
                                    className={classNames({
                                        'BtnSnowSensor': true,
                                        'BtnFan': isSnowEnableElectrical && !isWindOn && !isElectricalSchedOn
                                    })}
                                    disabled={isGrdFault || isWindOn || isElectricalSchedOn}
                                >
                                    <Snowflake fill={isSnowEnableElectrical || isSnowDetectionElectrical ? '#fff' : '#9a9a9a'}/>

                                    <span>Snow Sensor</span>

                                </Button>

                            </div>

                        </div>

                    </div> : null

            }

            <Divider className="mb-28 mt-19"/>

            <DataLogging/>

        </>
    );
}

export default observer(DeviceDetails);
