import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

const NonAuthmiddleware = ({ component: Component, layout: Layout, authStore }) => {
  return (
  <Route
    render={(props) => {
      if (authStore.userData) {
        console.log("Go to Dashboard")
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        );
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
)};

export default inject("authStore")(observer(withRouter(NonAuthmiddleware)));
