import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../mobxStores/stores";
import {Button, Row} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import Location from "./Location";
import Header from "./Header";
import Loader from "../Loader";
import './styles.scss';

const Loactions = () => {

    const
        {
            zoneStore:
                {
                    getZones,
                    loading,
                    getAllZones
                }
        } = useStore();

    useEffect(async () => {

        await getZones();

    }, []);

    if (loading) return <Loader/>

    return (
        <>
            <Header/>

            <div className="devices">

                <Row gutter={20}>
                    {
                        getAllZones && getAllZones.map((item, index) => (
                            <Location key={index} item={item}/>
                        ))
                    }
                </Row>

            </div>

            <Button className="add-device">

                <PlusOutlined/>

            </Button>
        </>
    );
};
export default observer(Loactions);
