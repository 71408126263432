import React from "react";
import {Col} from 'antd';
import {Link} from "react-router-dom";
import {CountryWeather} from "../../CountryWeather";
import {authStore} from "../../../mobxStores/auth.store";
import {observer} from "mobx-react";
import './styles.scss';

const requestImageFile = require.context("../../../assets/svg/", true, /.svg$/);

const Location = ({item}) => {

    const {userUnit} = authStore;

    return (

        <Col
            span={8}
            xs={20}
            lg={8}
            className="device gutter-row"
        >

            <div>

                <h5 className="device-title">{item.zone_name}</h5>

                <div className="device-content">

                    <Link
                        to=
                            {
                                '/dashboard/locations/' + localStorage.getItem('platform') + '/' + item.zone_id
                            }
                        className="d-image"
                    >
                        <img className="img-fluid w-100" src={item.zone_img_url} alt=""/>

                    </Link>

                    <div className="d-body d-flex justify-content-between align-items-center">

                        <div className="d-body-title">

                            <h6>

                                <Link
                                    to=
                                        {
                                            '/dashboard/locations/' + authStore.getPlatform() + '/' + item.zone_id
                                        }
                                >
                                    {
                                        item.zone_city
                                    }
                                </Link>

                            </h6>

                        </div>

                        <div className="temprature">

                            <span>
                                {
                                    Math.floor(item.currently.temperature)
                                }
                                <sup>°</sup>
                                {
                                    userUnit.toUpperCase()
                                }
                            </span>

                            <img
                                src=
                                    {
                                        requestImageFile(`./${item.currently.icon}.svg`).default
                                    }
                                alt={item.currently.icon}
                            />

                        </div>

                    </div>

                    <div className="devices d-flex flex-wrap justify-content-start align-items-center">

                        {
                            item.zone_devices && item.zone_devices.length
                                ? item.zone_devices.map((device, index) => <span key={index}>{device}</span>)
                                : <span className='emptyDevices'>_</span>
                        }

                    </div>

                    <div className="device-footer border-top d-flex justify-content-between align-items-center">
                        {
                            item.daily.data.slice(0, 7).map((day, index) => {
                                return <CountryWeather
                                    item=
                                        {
                                            {
                                                temp: Math.floor(((day.temperatureMax + day.temperatureMin) / 2)),
                                                icon: day.icon,
                                                isActive: new Date(day.time * 1000).toJSON().slice(0, 10).replace(/-/g, '/') === new Date(item.currently.time * 1000).toJSON().slice(0, 10).replace(/-/g, '/')

                                            }
                                        }
                                    key={index}
                                />
                            })
                        }
                    </div>

                </div>

            </div>

        </Col>
    );
};

export default observer(Location);
