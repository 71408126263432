import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {useStore} from "../../../mobxStores/stores";
import {authStore} from "../../../mobxStores/auth.store";
import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {message, Popconfirm, Table} from "antd";
import Loader from "../../Loader";
import moment from 'moment';
import HeatingProgramDialog from "../../HeatingProgramDialog";
import {zoneStore} from "../../../mobxStores/zones.store";
import './styles.scss';

const TableScheduledHeating = ({setSelectedRowKeys}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [record, setRecord] = useState({});

    const showModal = (record) => {
        console.log('recordsss', record)
        scheduleStore.findProgram(record)
        setRecord(scheduleStore.programComputed);
        setIsModalVisible(true);
    };

    const onFinish = async (values) => {
        try {
            await scheduleStore.updateSchedule({
                program_id: values.program_id,
                device_ids: values.switch,
                start_date: moment(values.start_date).utc().valueOf(),
                end_date: moment(values.end_date).utc().valueOf(),
                set_temp: values.set_temp,
                zone_id: zoneStore.zone.zone_id,
                note: values.note
            })
            setIsModalVisible(false);
            message.success('Schedule updated successfully')
        } catch (error) {
            setIsModalVisible(false);
            message.error(error.response.data.message);

        }

    };

    const handleOk = () => {
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const confirm = (key) => {
        scheduleStore.deleteSchedule(key);
        message.success('Schedule deleted successfully');
    }

    const cancel = (e) => {
    }

    const columns = [
        {
            title: 'Start Time',
            dataIndex: 'start_date',
            render: (text) => <a>{moment(text).format('YYYY/MM/DD LTS')}</a>,
        },
        {
            title: 'End Time',
            dataIndex: 'end_date',
            render: (text) => <a>{moment(text).format('YYYY/MM/DD LTS')}</a>,
        },
        {
            title: 'Temp',
            dataIndex: 'set_temp',
            render: text => <span className="text-uppercase">{text} <sup>°</sup>{authStore.userUnit}</span>
        },
        {
            title: 'Locations',
            dataIndex: 'device_ids',
            width: '40%',
        },
        {
            title: 'Created By',
            dataIndex: 'user',
        },
        {
            title: 'Notes',
            dataIndex: 'note',
        },
        {
            title: 'Action',
            dataIndex: '',
            fixed: 'right',
            key: 'x',
            render: (text, record) =>
                <div>
                    <Popconfirm
                        title="Are you sure to delete scheduled?"
                        onConfirm={() => confirm(record.key)}
                        onCancel={(e) => cancel(e)}
                        okText="Yes"
                        cancelText="Cancel"
                    >
                        <span onClick={e => onDelete(record.key, e)}> <DeleteOutlined/></span>
                    </Popconfirm>
                    <span onClick={() => showModal(record)}> <EditOutlined/></span>
                </div>,
        }
    ];

    const onDelete = (key, e) => {
        e.preventDefault();
        console.log('key', key)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            action: (a) => {
                a.key(record.key)
            }
        }),
        onClick: (selected) => {
            console.log('selected', selected)
        }
    };

    const {scheduleStore} = useStore();

    const {id} = useParams();

    useEffect(async () => {
        await scheduleStore.fetchSchedules(id);
    }, [])

    if (scheduleStore.loading) {
        return <Loader/>
    }

    return <div className="table-responsive">
        {
            isModalVisible
            && <HeatingProgramDialog
                handleCancel={handleCancel}
                onFinish={onFinish}
                handleOk={handleOk}
                isModalVisible={isModalVisible}
                type={'edit'}
                record={record}
            />
        }

        {
            <Table
                rowSelection={{
                    type: 'checkbox',
                    key: 'key',
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={scheduleStore.computedSchedules}
                locale={{

                    emptyText: <span
                        className="d-flex text-000 justify-content-center align-items-center"
                        style={{fontSize: 13, height: 106}}
                    >
                        No Data Found
                    </span>

                }}
            />
        }
    </div>


}

export default observer(TableScheduledHeating);
