import React, {useState} from 'react';
import {observer} from "mobx-react";
import {useParams} from 'react-router-dom';
import {Button, Card, message, Typography} from "antd";
import ButtonCustom from "../Button";
import {authStore} from "../../mobxStores/auth.store";
import {ReactComponent as Tick} from "../../assets/svg/save.svg";
import {zoneStore} from "../../mobxStores/zones.store";
import './styles.scss';

const OveralTemperature = () => {
    const {id} = useParams();

    const [temp, setTemp] = useState(25);

    const {Text} = Typography

    const {userUnit} = authStore;

    const confirmTemp = async () => {
        if (temp < 25 || temp > 999) {
            message.warn('Temp must be between 25 - 999');
            return;
        }
        await zoneStore.setOverallTemp({
            zoneId: id,
            temp
        });
        setShowBtn(false)
    }

    const [showBtn, setShowBtn] = useState(false);

    return (
        <div className="OveralTemperature">

            <Text className="text-6d6d6d title d-block text-uppercase">set overall temperature</Text>

            <Card className="Card" bordered={false}>

                <div className="position-relative">

                    <Typography className="Body text-000 d-flex justify-content-between align-items-center">

                        <ButtonCustom
                            type="-"
                            onClick={
                                () => {
                                    setTemp(prevState => prevState - 1);
                                    setShowBtn(true);
                                }
                            }
                        />

                        <Text className="set-temp">
                            {
                                temp + '°' + userUnit.toUpperCase()
                            }
                        </Text>

                        <ButtonCustom
                            type="+"
                            onClick={
                                () => {
                                    setTemp(prevState => prevState + 1)
                                    setShowBtn(true);
                                }
                            }
                        />

                    </Typography>

                    {
                        showBtn &&
                        <Button
                            onClick={confirmTemp}
                            className="BtnOverallSetTemp position-absolute"
                        >

                            <Tick style={{width: '20', height: 'auto'}} fill="#3ccab0"/>

                        </Button>
                    }

                </div>

            </Card>

        </div>
    );
}

export default observer(OveralTemperature);
