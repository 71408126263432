import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import {useStore} from "../../mobxStores/stores";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  path
}) => {
    const {authStore} = useStore()
  return (
    <Route
      render={ (props) => {
          if (!authStore.userData) {
              /*
              * Get system lookups if they are a current user
              * */
            return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
      path={path}
    />
  );
};

export default inject('authStore')(observer(withRouter(Authmiddleware)));
