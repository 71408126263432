import {useApi as RestApi} from "../utils/API";
import {selectPlatform} from "../utils/helper";

const {post, get} = RestApi();

const fetchSchedules = async (payload) => {
    try {
        return await get(selectPlatform() + '/get-schedules/' + payload.id);
    } catch (error) {
        throw error;
    }
}
const deleteSchedule = async (payload) => {
    try {
        console.log({payload})
        return await post(selectPlatform() + '/delete-schedule/', payload);
    } catch (e) {
        throw e;
    }
}
const addSchedule = async (payload) => {
    try {
        return await post(selectPlatform() + '/add-schedule', payload);
    } catch (e) {
        throw e;
    }
}
const updateSchedule = async (payload) => {
    try {
        return await post(selectPlatform() + '/update-schedule', payload);
    } catch (e) {
        throw e;
    }
}

export {fetchSchedules, deleteSchedule, addSchedule, updateSchedule};
