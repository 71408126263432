import {useApi as RestApi} from "../utils/API";
import api from "../utils/EndPoints";
import {selectPlatform} from "../utils/helper";

const {get, post} = RestApi();

const getAllZones = async (payload) => {
    try {
        let platform = 'blowers';
        if (localStorage.getItem('platform')) {
            platform = localStorage.getItem('platform');
        }
        return await get(api.getZones + platform, payload);
    } catch (error) {
        throw error;
    }
};

const getCurrentZone = async (payload) => {
    try {
        return await get(api.getCurrentZone + payload.id);
    } catch (error) {
        throw error;
    }
};

const setSnowSensor = async (payload) => {
    try {
        await post(selectPlatform() + api.masterSnowSensor + payload.zoneId + '/' + payload.status)
    } catch (error) {
        throw error;
    }
}
const setOverallTemp = async (payload) => {
    try {
        return await post(selectPlatform() + api.setOverallTemp, payload)
    } catch (error) {
        throw error;
    }
}
const windTimer = async (payload) => {
    try {

        return await post(selectPlatform() + api.windTimer, payload);
    } catch (error) {
        throw error;
    }
}

export {getAllZones, getCurrentZone, setSnowSensor, setOverallTemp, windTimer};
