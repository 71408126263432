import {Typography} from "antd";
import {authStore} from "../../../mobxStores/auth.store";
import './styles.scss';

const Trigger = ({item}) => {
    const {userUnit, userSpeed} = authStore;
    const {Text} = Typography;
    return (
        <div className="Trigger">

            <div className="Details">

                <div className="Type">

                    <Text>{item.miles}-{item.hour}</Text>

                    <Text>{userSpeed.toUpperCase()}/h</Text>

                </div>

                <Text className="Temp">{item.temp}<sup>°</sup>{userUnit.toUpperCase()}</Text>

            </div>

            <Text className="Name text-979797">{item.name}</Text>

        </div>
    );
}

export default Trigger;
