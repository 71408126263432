import {Spin} from "antd";

const Loader = () => (
    <div className="d-flex justify-content-center align-items-center">

        <Spin
            tip="Loading..."
            className="text-20ace8"
        />

    </div>
);
export default Loader;
