import React, {useState} from "react";
import {Button, Col, DatePicker, Modal, Row, Space, Typography} from "antd";
import moment from "moment";
import {ArrowRightOutlined} from '@ant-design/icons';
import {ReactComponent as CaretDown} from "../../assets/svg/CaretDown.svg";
import ButtonCustom from "../Button";
import {authStore} from "../../mobxStores/auth.store";
import {zoneStore} from "../../mobxStores/zones.store";
import {Formik, Form} from 'formik';
import {observer} from "mobx-react";
import FormikControl from "../FormikControl";
import {addScheduleValidationSchema} from "../../utils/ValidationSchemas";
import {toJS} from "mobx";
import CustomButton from "../CustomButton";
import './styles.scss';

const HeatingProgramDialog = ({isModalVisible, onFinish, handleCancel, type = 'add', record = {}}) => {

    const [temp, setTemp] = useState(record.set_temp || 25);

    const dateFormat = 'YYYY-MM-DD LTS';

    const getCurrentDate = (type) => {
        if (type === 'start_date') {
            return record.start_date && moment(new Date(record.start_date), dateFormat, true)
        } else if (type === 'end_date') {
            return record.end_date && moment(new Date(record.end_date), dateFormat, true)
        }
    }

    const updateDate = (e, type, formik) => {
        formik.setFieldValue(type, e)
    }

    const {Title, Text} = Typography;

    return (
        <Modal
            title={
                <Title
                    level={4}
                    className="text-000 m-0 text-capitalize"
                    style={{fontSize: 18}}
                >
                    {type} Heating Program
                </Title>
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[]}
        >

            <Formik
                initialValues={{
                    switch: record.device_ids,
                    set_temp: temp,
                    start_date: record.start_date && moment(new Date(record.start_date), dateFormat, true),
                    end_date: record.end_date && moment(new Date(record.end_date), dateFormat, true),
                    note: record.note || '',
                    program_id: record.program_id
                }}
                validationSchema={addScheduleValidationSchema}
                onSubmit={onFinish}
            >
                {
                    formik => {
                        return (
                            <Form onSubmit={formik.handleSubmit}>
                                <FormikControl
                                    control="input"
                                    name="program_id"
                                    type="hidden"
                                />

                                <Row className="no-gutters mb-30">

                                    <Col>

                                        <FormikControl
                                            control='multiselect'
                                            name='switch'
                                            defaultValue={record.device_ids}
                                            label={'Select Switch'}
                                            placeholder="select device"
                                            onSelect={(value) => {
                                                formik.setFieldValue("switch", value)
                                                console.log('value111', value)
                                            }}
                                            onBlur={() => formik.setFieldTouched("switch", true)}
                                            options={toJS(zoneStore.options)}
                                            required
                                        />

                                    </Col>

                                    <Col style={{minWidth: 214, marginLeft: 43}}>

                                        <Text className="d-block text-uppercase text-979797 text-12px mb-12">set
                                            temperature *</Text>

                                        <Typography
                                            className="Body text-000 d-flex justify-content-between align-items-center">
                                            <ButtonCustom type="-" onClick={() => {
                                                setTemp(temp - 1);
                                                formik.setFieldValue('set_temp', temp - 1)
                                            }}/>

                                            <Text
                                                className="set-temp">{temp}<sup>o</sup>{authStore.userUnit.toUpperCase()}
                                            </Text>

                                            <ButtonCustom type="+" onClick={() => {
                                                setTemp(prevState => temp + 1);
                                                formik.setFieldValue('set_temp', temp + 1)
                                            }}/>

                                        </Typography>

                                    </Col>

                                </Row>

                                <Row className="no-gutters mb-24">
                                    <Col>

                                        <Text className="d-block text-uppercase text-979797 text-12px mb-8">SELECT START
                                            DATE / TIME *</Text>

                                        <Space direction="vertical" size={12}>

                                            <DatePicker
                                                className="DatePicker SelectDevice"
                                                showTime
                                                defaultValue={getCurrentDate('start_date')}
                                                placeholder="SELECT START DATE / TIME *"
                                                suffixIcon={<CaretDown/>}
                                                onChange={(e) => updateDate(e, 'start_date', formik)}
                                                onOk={(e) => updateDate(e, 'start_date', formik)}/>

                                        </Space>

                                    </Col>

                                    <Col
                                        style={{width: 43, paddingTop: 30}}
                                        className="text-center"
                                    >
                                        <ArrowRightOutlined className="text-979797"/>
                                    </Col>

                                    <Col>
                                        <Text className="d-block text-uppercase text-979797 text-12px mb-8">SELECT END
                                            DATE / TIME *</Text>

                                        <Space direction="vertical" size={12}>

                                            <DatePicker
                                                className="DatePicker SelectDevice text-1d1d1f" showTime
                                                defaultValue={getCurrentDate('end_date')}
                                                placeholder="SELECT END DATE / TIME *" suffixIcon={<CaretDown/>}
                                                onChange={(e) => updateDate(e, 'end_date', formik)}
                                                onOk={(e) => updateDate(e, 'end_date', formik)}
                                            />

                                        </Space>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col span={24}>

                                        <Text className="d-block text-uppercase text-979797 text-12px mb-8">DESCRIPTION
                                            IF APPLICABLE</Text>

                                        <FormikControl
                                            control='input'
                                            type='textarea'
                                            name='note'
                                            className="TextAreaHeating"
                                        />

                                    </Col>

                                </Row>

                                <div className="mt-4 d-flex justify-content-end">

                                    <Button className="footerBtn text-uppercase bg-sec text-9c9c9c"
                                            key="back"
                                            onClick={handleCancel}>
                                        Cancel
                                    </Button>

                                    <CustomButton
                                        key="submit"
                                        label={'Submit'}
                                        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                                        loading={formik.isSubmitting}
                                        onClick={onsubmit}
                                        type="submit"
                                        className="footerBtn text-uppercase bg-pri"
                                    />

                                </div>

                            </Form>
                        )
                    }
                }
            </Formik>
        </Modal>
    );
}
export default observer(HeatingProgramDialog);
