import React from "react";
import {Dropdown, Layout, Menu} from 'antd';
import {ReactComponent as Logo} from "../../assets/svg/Group 85.svg";
import {Link} from "react-router-dom";
import {authStore} from "../../mobxStores/auth.store";
import {LogoutOutlined} from "@ant-design/icons";
import './styles.scss';

const Header = () => {
    const {getNickName, logout} = authStore;

    const handleMenuClick = (e) => {
        logout();
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item
                className="d-flex justify-content-center align-items-center"
                key="1"
                icon={<LogoutOutlined/>}
            >

                Logout

            </Menu.Item>

        </Menu>
    );
    return (
        <Layout.Header className="Header d-block d-lg-none">

            <div className="d-flex justify-content-between align-items-center h-100">

                <Link to="/dashboard"><Logo/></Link>

                <Dropdown overlay={menu} placement="bottomLeft">

                    <div className="icon">

                        <span className="text-uppercase cursor-pointer">{getNickName}</span>

                    </div>

                </Dropdown>

            </div>

        </Layout.Header>
    );
}

export default Header;
