import {observer} from "mobx-react";
import {useParams} from 'react-router-dom';
import {useStore} from "../../mobxStores/stores";
import {Row, Typography} from "antd";
import Device from "./Device";
import Loader from "../Loader";

const Devices = () => {

    const {id} = useParams();

    const marginStyles = {marginRight: '-10px', marginLeft: '-10px'};

    const {zoneStore: {loading, getDevicesComputed}} = useStore();

    console.log('match', id);

    if (loading) return <Loader/>

    return (
        <>
            <Row className="Devices" style={marginStyles}>
                {
                    getDevicesComputed &&
                    getDevicesComputed.length
                        ? getDevicesComputed.map((item, index) => (
                            <Device key={index} device={item}/>))
                        :
                        <Typography.Title
                            level={5}
                            className="text-uppercase text-center d-block m-auto">
                            no devices
                        </Typography.Title>
                }
            </Row>

        </>
    );
}

export default observer(Devices);
