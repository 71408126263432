import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {useStore} from "../../../mobxStores/stores";
import {Table} from "antd";
import Loader from "../../Loader";
import './styles.scss';

const TableAuditHistory = () => {

    const columns = [
        {
            title: 'Device Mac',
            dataIndex: 'device_mac',
        },
        {
            title: 'Username',
            dataIndex: 'user',
        },
        {
            title: 'Prev Value',
            dataIndex: 'prev_value',
            // render: (text) => <span>{Object.keys(JSON.parse(text))} : {Object.values(JSON.parse(text))}</span>,
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'New Value',
            dataIndex: 'new_value',
            render: (text) => <span>{text}</span>,

        }
    ];

    const {deviceStore} = useStore();

    const {id} = useParams();

    useEffect(async () => {
        await deviceStore.fetchAudits({mac: id, page: 1, entities: 20});
    }, [])

    if (deviceStore.loadingAudits) {
        return <Loader/>
    }

    return <div className="table-responsive">

        {
            <Table
                columns={columns}
                dataSource={deviceStore.audits}
                locale={{

                    emptyText: <span
                        className="d-flex text-000 justify-content-center align-items-center"
                        style={{fontSize: 13, height: 106}}
                    >
                        No Data Found
                    </span>

                }}
            />
        }
    </div>


}

export default observer(TableAuditHistory);
