import React from "react";
import {ErrorMessage} from "formik";
import TextError from "./TextError";
import FormGroup from "reactstrap/lib/FormGroup";
import ReactSelect, {components} from "react-select";

const CaretDownIcon = () => {
    return <></>;
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>
    );
};


const Option = ({innerProps, onSelect, id, isSelected, label, isDisabled, onClick}) =>
    (
        <div {...innerProps} className="p-3">
            <div key={id} className="custom-control custom-checkbox mm-select-menu m-0">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={label}
                    checked={isSelected}
                    onChange={onSelect}
                />
                <label
                    className="custom-control-label"
                    htmlFor={label}
                >
                    {label}
                </label>
            </div>
        </div>
    );

function MultiSelect(props) {
    const {
        label,
        defaultLabel,
        required,
        style,
        name,
        defaultValue,
        options,
        onSelect = () => {
        },
    } = props;

    console.log({defaultValue})
    return (
        <FormGroup className="multi-select-outer" style={style}>
            {label && (
                <label className={[`text-uppercase text-979797 text-12px mb-8 ${required && "required"}`]}
                       htmlFor={name}>
                    {label}
                </label>
            )}
            <ReactSelect
                options={options}
                defaultValue={defaultValue}
                className="SelectDevice text-1d1d1f text-1d1d1f text-12px"
                classNamePrefix="select-device"
                name={name}
                placeholder={defaultLabel}
                onChange={options => onSelect(options)}
                isMulti
                removeSelected={false}
                closeMenuOnSelect={false}
            />
            <ErrorMessage component={TextError} name={name}/>
        </FormGroup>
    );
}

export default MultiSelect;
